import downloadjs from 'downloadjs'

import handleResponse from '../../utils/handleApiResponse'
import { BACKEND_URL } from '../../config'
import { headers } from '../../utils/apiHeaders'
import handleError from '../../utils/handleApiError'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGuaranteesListService = (requestData: any) => {
  let URL_ADMIN_API_GUARANTEE_REQUEST = BACKEND_URL + '/api/guarantee'
  if (requestData.length) {
    URL_ADMIN_API_GUARANTEE_REQUEST += `?search=${requestData}`
  }

  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(URL_ADMIN_API_GUARANTEE_REQUEST, requestOptions)
    .then(handleResponse)
    .then((responseList) => responseList)
    .catch(handleError)
}

export const downloadGuaranteeReport = () => {
  const URL_ADMIN_API_GUARANTEE_REPORT_REQUEST = BACKEND_URL + '/api/guarantee/report'
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(URL_ADMIN_API_GUARANTEE_REPORT_REQUEST, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Something went wrong')
      }
      return response.blob()
    })
    .then((blob) => downloadjs(blob, 'Guarantees Report', 'application/octet-stream'))
}

export const deleteGuarantee = (guaranteeNumber: string) => {
  const URL_ADMIN_API_DELETE_GUARANTEE_REQUEST = BACKEND_URL + `/api/guarantee/${guaranteeNumber}`
  const requestOptions = {
    method: 'DELETE',
    headers: headers(),
  }

  return fetch(URL_ADMIN_API_DELETE_GUARANTEE_REQUEST, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Something went wrong')
      }
    })
    .catch(handleError)
}
