import { JWT_USER_TOKEN } from '../config'

export const headers = (contentType = 'application/json') => {
  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Content-Type', contentType)
  if (sessionStorage.getItem(JWT_USER_TOKEN)) {
    requestHeaders.set('Authorization', sessionStorage.getItem(JWT_USER_TOKEN) || '')
  }
  return requestHeaders
}

export const noContentTypeHeaders = () => {
  const requestHeaders: HeadersInit = new Headers()
  if (sessionStorage.getItem(JWT_USER_TOKEN)) {
    requestHeaders.set('Authorization', sessionStorage.getItem(JWT_USER_TOKEN) || '')
  }
  requestHeaders.append('Accept', 'application/json')
  return requestHeaders
}
