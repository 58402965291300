import { AnyAction, Reducer } from 'redux'

import {
  AUTH_USER_DONE,
  AUTH_USER_LOGOUT_DONE,
  AUTH_USER_START,
  UNAUTHORIZED_ERROR,
  UNEXPECTED_ERROR,
} from './auth.actions'

type AuthStateType = {
  inProgress: boolean
  token: string | null
  unauthorizedError: Error | null
}

const initialState: AuthStateType = {
  inProgress: false,
  token: null,
  unauthorizedError: null,
}

const reducer: Reducer<AuthStateType, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_LOGOUT_DONE:
      return initialState

    case AUTH_USER_START:
      return {
        ...state,
        inProgress: true,
      }

    case AUTH_USER_DONE:
      return {
        ...state,
        inProgress: false,
        token: action.token,
      }

    case UNAUTHORIZED_ERROR:
      return {
        ...state,
        inProgress: false,
        unauthorizedError: action.error,
      }

    case UNEXPECTED_ERROR:
      return {
        ...state,
        inProgress: false,
        unexpectedError: action.error,
      }

    default:
      return state
  }
}

export default reducer
