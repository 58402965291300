export enum RepairRequestSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type AdminRepairRequestsFilter = {
  search?: string
  sortBy?: string
  sortOrder?: RepairRequestSortOrder
}
