import { put, call, takeLatest } from 'redux-saga/effects'

import {
  convertRepairRequestStatusFormToData,
  deleteRepairRequest,
  getRepairRequestDetails,
  getRepairRequests,
  putRepairRequestStatus,
} from './adminRepairRequest.service'
import {
  ADMIN_REPAIR_REQUEST_DELETE_START,
  ADMIN_REPAIR_REQUEST_FETCH_DETAILS_START,
  ADMIN_REPAIR_REQUEST_FETCH_LIST_START,
  ADMIN_REPAIR_REQUEST_PUT_STATUS_START,
  deleteAdminRepairRequestDone,
  deleteAdminRepairRequestError,
  deleteAdminRepairRequestInProgress,
  fetchAdminRepairRequestDetailsDone,
  fetchAdminRepairRequestDetailsError,
  fetchAdminRepairRequestDetailsInProgress,
  fetchAdminRepairRequestsListDone,
  fetchAdminRepairRequestsListError,
  fetchAdminRepairRequestsListInProgress,
  IDeleteAdminRepairRequestStartAction,
  IFetchAdminRepairRequestDetailsStartAction,
  IFetchAdminRepairRequestsListStartAction,
  IPutAdminRepairRequestStatusStartAction,
  putAdminRepairRequestStatusDone,
  putAdminRepairRequestStatusError,
  putAdminRepairRequestStatusInProgress,
} from './adminRepairRequest.action'

import { authUserLogoutAction } from '../../auth/auth.actions'
import { RepairRequestData } from '../../requests/repair-request/repairRequest.domain'

// calling get list for admin
export function* watchForFetchAdminRepairRequestsListStart() {
  yield takeLatest(ADMIN_REPAIR_REQUEST_FETCH_LIST_START, callGetRepairRequestsList)
}

function* callGetRepairRequestsList(action: IFetchAdminRepairRequestsListStartAction) {
  try {
    yield put(fetchAdminRepairRequestsListInProgress())
    const response: RepairRequestData[] = yield call(getRepairRequests, action.filter)
    yield put(fetchAdminRepairRequestsListDone(response))
  } catch (error: unknown) {
    yield put(fetchAdminRepairRequestsListError(error as Error))
    yield put(authUserLogoutAction())
  }
}

// calling get details for admin
export function* watchForFetchAdminRepairRequestDetailsStart() {
  yield takeLatest(ADMIN_REPAIR_REQUEST_FETCH_DETAILS_START, callGetRepairRequestDetails)
}

function* callGetRepairRequestDetails(action: IFetchAdminRepairRequestDetailsStartAction) {
  try {
    yield put(fetchAdminRepairRequestDetailsInProgress())
    const response: RepairRequestData = yield call(getRepairRequestDetails, action.repairRequestId)
    yield put(fetchAdminRepairRequestDetailsDone(response))
  } catch (error: unknown) {
    yield put(fetchAdminRepairRequestDetailsError(error as Error))
    yield put(authUserLogoutAction())
  }
}

// calling put status for admin
export function* watchForPutAdminRepairRequestStatusStart() {
  yield takeLatest(ADMIN_REPAIR_REQUEST_PUT_STATUS_START, callPutRepairRequestStatus)
}
function* callPutRepairRequestStatus(action: IPutAdminRepairRequestStatusStartAction) {
  try {
    yield put(putAdminRepairRequestStatusInProgress())

    const data = convertRepairRequestStatusFormToData(action.repairRequestStatusForm)
    const documentFile = action.repairRequestStatusForm.file
    const response: RepairRequestData = yield call(
      putRepairRequestStatus,
      action.requestId,
      data,
      documentFile
    )
    yield put(putAdminRepairRequestStatusDone(response))
  } catch (error: unknown) {
    yield put(putAdminRepairRequestStatusError(error as Error))
    // yield put(authUserLogoutAction())
  }
}

// calling delete repair request
export function* watchForDeleteAdminRepairRequestStart() {
  yield takeLatest(ADMIN_REPAIR_REQUEST_DELETE_START, callDeleteAdminRepairRequest)
}

function* callDeleteAdminRepairRequest(action: IDeleteAdminRepairRequestStartAction) {
  try {
    yield put(deleteAdminRepairRequestInProgress())
    yield call(deleteRepairRequest, action.repairRequestId)
    yield put(deleteAdminRepairRequestDone(action.repairRequestId))
  } catch (error: unknown) {
    yield put(deleteAdminRepairRequestError(error as Error))
  }
}
