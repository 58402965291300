import React from 'react'
import { useTranslation } from 'react-i18next'

import { RepairRequestStatusTree } from './RepairRequestStatusTree'

import { PageTitle } from '../common'
import { TextContentLoader } from '../common/loaders'
import {
  dictionaryProductCategory,
  dictionaryRepairRequestStatus,
  RepairRequestData,
} from '../../requests/repair-request/repairRequest.domain'

import './RepairRequestStatusLayout.scss'

type Props = {
  repairRequest: RepairRequestData
  pageTitle: string
  pageSubtitle?: string
}

export const RepairRequestStatusLayout = (props: Props) => {
  const { repairRequest, pageTitle, pageSubtitle } = props
  const { t } = useTranslation()

  return (
    <>
      <PageTitle value={pageTitle} />
      {pageSubtitle && <div className="font-weight-bold mt-3">{pageSubtitle}</div>}
      <div className="mt-3">
        {repairRequest ? (
          <>
            <div className="fs-3 fw-600 mb-4">{repairRequest.requestId}</div>
            <div className="fs-3 fw-400 text-uppercase mb-1">
              {(
                dictionaryProductCategory.item(repairRequest.productCategory) ||
                repairRequest.productCategory
              ).toString()}
            </div>
            <div>{repairRequest.productModel}</div>
          </>
        ) : (
          <TextContentLoader mode="small" />
        )}
      </div>

      <div>
        <div className="col-12">
          {repairRequest && repairRequest.currentStatus ? (
            <div className="mt-5">
              <div className="fs-3 fw-400 text-uppercase mb-4">
                {t('repairRequestStatus.status')}
              </div>
              <div className="mb-1 fs-3 fw-600">
                {t(
                  dictionaryRepairRequestStatus.item(repairRequest.currentStatus.toString()).value
                )}
              </div>
              <div>
                {t(
                  dictionaryRepairRequestStatus.item(repairRequest.currentStatus.toString())
                    .description as string
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <RepairRequestStatusTree hideInvoiceFile={true} repairRequest={repairRequest} />
    </>
  )
}
