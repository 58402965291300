export const Button = ({
  children,
  className = '',
  noMargin,
  noPadding,
  disabled = false,
  type = 'button',
  onClick,
  style,
}: {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  noMargin?: boolean
  noPadding?: boolean
  style?: React.CSSProperties
}) => {
  return (
    <button
      className={`border border-gray text-gray-10 uppercase ${className} ${
        noMargin ? '' : 'ml-2'
      } ${noPadding ? '' : 'px-4 py-2'}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      style={style}
    >
      {children}
    </button>
  )
}
