import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import NewPageTitle from '../common/new-page-title/NewPageTitle'
import { Button } from '../common/button/Button'
import downloadIcon from '../../assets/icons/downloadIcon.svg'
import { useAppSelector } from '../../utils/hooks'
import { ErrorBox } from '../common/errorBox/ErrorBox'
import { getPriceListFile, getPriceListTypeList } from '../../requests/price-list'
import { Spinner } from '../common/spinner/Spinner'

export const PriceList = () => {
  const userStore = useAppSelector((state) => state.user)
  const rmaCustomerId = userStore.userData?.rmaCustomerId
  // const [showErrorLackOfCustomerRmaID, setShowErrorLackOfCustomerRmaID] = useState<
  //   boolean | string
  // >(false)
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false)
  const { t } = useTranslation()
  const [priceListTypeList, setPriceListTypeList] = useState<Array<{ [key: string]: string }>>([])
  const [loadingData, setLoadingData] = useState<boolean>(true)

  interface PriceListResponse {
    [key: string]: string
  }
  const priceListValue: PriceListResponse = {
    B2B: t('priceList.B2B') as string,
    B2C: t('priceList.B2C') as string,
    SERVICE_PRICE_LIST: t('priceList.SERVICE_PRICE_LIST') as string,
  }
  const setProperTypesPriceList = useCallback((priceListTypes: PriceListResponse) => {
    const priceListArray: Array<{ [key: string]: string }> = []
    for (const key in priceListTypes) {
      if (Object.prototype.hasOwnProperty.call(priceListTypes, key)) {
        const obj: { [key: string]: string } = {}
        obj[key] = priceListTypes[key]
        priceListArray.push(obj)
      }
    }
    setPriceListTypeList(priceListArray)
  }, [])

  const handlePriceListInfo = useCallback(async () => {
    if (!rmaCustomerId) {
      // setShowErrorLackOfCustomerRmaID(true)
      setLoadingData(false)
      return
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const priceListTypes: any = await getPriceListTypeList(rmaCustomerId)
      setProperTypesPriceList(priceListTypes)
    } catch {
      setShowErrorMessage('Error fetching price list types')
    } finally {
      setLoadingData(false)
    }
  }, [setProperTypesPriceList, rmaCustomerId])

  const handlePdfDownload = (priceListType: string) => {
    if (!rmaCustomerId) {
      return
    }
    getPriceListFile(rmaCustomerId, priceListType)
  }

  useEffect(() => {
    handlePriceListInfo()
  }, [handlePriceListInfo])

  const removeFileExtension = (filename: string): string => {
    const lastDotIndex = filename?.lastIndexOf('.')
    if (lastDotIndex !== -1) {
      return filename.substring(0, lastDotIndex)
    }
    return filename
  }

  return (
    <div className="pt-8 pb-8" style={{ height: 'calc(100vh - 246px)' }}>
      <NewPageTitle topText={t('priceList.topText')} title={t('priceList.title')} backButton>
        <div className="w-[46rem] mb-12">
          <div className="flex">
            {priceListTypeList.length > 0 ? (
              <>
                <div className="w-full md:w-3/12">
                  <span className="text-gray">{t('priceList.priceListType')}</span>
                </div>
                <div className="w-full md:w-7/12">
                  <span className="text-gray">{t('priceList.fileName')}</span>
                </div>
                <div className="w-full md:w-2/12">
                  <span className="text-gray">{t('priceList.pdfDownload')}</span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {priceListTypeList?.map((item, index) => {
            const key = Object.keys(item)[0]
            const fileName = removeFileExtension(item[key])
            return (
              <div className="flex py-8 border-b border-1 border-solid border-gray-10" key={index}>
                <div className="w-full md:w-3/12">
                  <span>{priceListValue[key]}</span>
                </div>
                <div className="w-full md:w-7/12">
                  <span>{fileName}</span>
                </div>
                <div className="w-full md:w-2/12">
                  <Button
                    noMargin
                    noPadding
                    className="border-none"
                    onClick={() => handlePdfDownload(key)}
                  >
                    <img src={downloadIcon} alt="" width={32} />
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
        {priceListTypeList.length === 0 && !loadingData ? (
          <span className="text-2xl text-gray">{t('priceList.noPriceList')}</span>
        ) : (
          <></>
        )}

        {loadingData ? <Spinner text={t('common.loadingData')} /> : <></>}
      </NewPageTitle>
      {/*{showErrorLackOfCustomerRmaID ? (*/}
      {/*  <ErrorBox*/}
      {/*    title={t('priceList.error')}*/}
      {/*    text={`${t('priceList.errorLackOfCustomerRmaID')}`}*/}
      {/*    onClick={() => setShowErrorLackOfCustomerRmaID(false)}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <></>*/}
      {/*)}*/}
      {showErrorMessage ? (
        <ErrorBox
          title={t('priceList.error')}
          text={`${t('priceList.errorFetchPriceList')}`}
          onClick={() => setShowErrorMessage(false)}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
