import React from 'react'
import ContentLoader from 'react-content-loader'

type Props = {
  mode?: 'small' | 'medium' | 'big' // default big
}

export const TextContentLoader = (props: Props) => {
  if (props.mode === 'small') {
    return (
      <ContentLoader
        height={160}
        width={120}
        speed={1}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="6" y="7" rx="3" ry="3" width="88" height="6" />
        <rect x="7" y="29" rx="3" ry="3" width="52" height="6" />
      </ContentLoader>
    )
  } else if (props.mode === 'medium') {
    return (
      <ContentLoader
        height={160}
        width={300}
        speed={1}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="15" y="9" rx="3" ry="3" width="297" height="6" />
        <rect x="15" y="20" rx="3" ry="3" width="270" height="6" />
        <rect x="15" y="34" rx="3" ry="3" width="178" height="6" />
      </ContentLoader>
    )
  } else {
    return (
      <ContentLoader
        height={160}
        width={400}
        speed={1}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="59" rx="4" ry="4" width="117" height="6" />
        <rect x="0" y="80" rx="3" ry="3" width="350" height="6" />
        <rect x="0" y="100" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="120" rx="3" ry="3" width="201" height="6" />
        <rect x="26" y="31" rx="0" ry="0" width="0" height="0" />
        <rect x="45" y="40" rx="0" ry="0" width="0" height="0" />
        <rect x="1" y="18" rx="0" ry="0" width="347" height="20" />
        <rect x="62" y="27" rx="0" ry="0" width="0" height="0" />
      </ContentLoader>
    )
  }
}
