import { Reducer } from 'redux'

import {
  FAQ_REQUEST_FETCH_START,
  FAQ_REQUEST_FETCH_IN_PROGRESS,
  FAQ_REQUEST_FETCH_DONE,
  FAQ_REQUEST_FETCH_ERROR,
  IFAQFetchStartAction,
  IFAQFetchInProgressAction,
  IFAQFetchDoneAction,
  IFAQFetchErrorAction,
} from './faqRequest.action'
import { FaqType } from './faqRequest.domain'

type FaqActionTypes =
  | IFAQFetchStartAction
  | IFAQFetchInProgressAction
  | IFAQFetchDoneAction
  | IFAQFetchErrorAction

type FaqRequestStateType = {
  faqData: FaqType[]
  inProgress: boolean
  error?: Error | null
}

const initialState: FaqRequestStateType = {
  faqData: [],
  inProgress: false,
  error: null,
}
const reducer: Reducer<FaqRequestStateType, FaqActionTypes> = (
  state: FaqRequestStateType = initialState,
  action: FaqActionTypes
) => {
  switch (action.type) {
    case FAQ_REQUEST_FETCH_START:
      return {
        ...state,
      }
    case FAQ_REQUEST_FETCH_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case FAQ_REQUEST_FETCH_DONE:
      return {
        ...state,
        faqData: action.faqData,
        inProgress: false,
      }

    case FAQ_REQUEST_FETCH_ERROR:
      return {
        ...state,
        faqData: [],
        error: action.error,
        inProgress: false,
      }

    default:
      return state
  }
}
export default reducer
