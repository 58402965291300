import { Action } from 'redux'

export const ADMIN_RESET_USER_PASSWORD_START = 'ADMIN/USER_MANAGEMENT/RESET_USER_PASSWORD_START'
export const ADMIN_RESET_USER_PASSWORD_IN_PROGRESS =
  'ADMIN/USER_MANAGEMENT/RESET_USER_PASSWORD_IN_PROGRESS'
export const ADMIN_RESET_USER_PASSWORD_DONE = 'ADMIN/USER_MANAGEMENT/RESET_USER_PASSWORD_DONE'
export const ADMIN_RESET_USER_PASSWORD_ERROR = 'ADMIN/USER_MANAGEMENT/RESET_USER_PASSWORD_ERROR'

export interface IAdminUserResetPasswordStartAction extends Action {
  type: typeof ADMIN_RESET_USER_PASSWORD_START
  username: string
}
export const resetUserPasswordActionStart = (
  username: string
): IAdminUserResetPasswordStartAction => {
  return {
    type: ADMIN_RESET_USER_PASSWORD_START,
    username,
  }
}

export interface IAdminUserResetPasswordInProgressAction extends Action {
  type: typeof ADMIN_RESET_USER_PASSWORD_IN_PROGRESS
}
export const resetUserPasswordActionInProgress = (): IAdminUserResetPasswordInProgressAction => {
  return {
    type: ADMIN_RESET_USER_PASSWORD_IN_PROGRESS,
  }
}

export interface IAdminUserResetPasswordDoneAction extends Action {
  type: typeof ADMIN_RESET_USER_PASSWORD_DONE
  password: string
}
export const resetUserPasswordActionDone = (
  password: string
): IAdminUserResetPasswordDoneAction => {
  return {
    type: ADMIN_RESET_USER_PASSWORD_DONE,
    password,
  }
}

export interface IAdminUserResetPasswordErrorAction extends Action {
  type: typeof ADMIN_RESET_USER_PASSWORD_ERROR
  error: Error
}
export const resetUserPasswordActionError = (error: Error): IAdminUserResetPasswordErrorAction => {
  return {
    type: ADMIN_RESET_USER_PASSWORD_ERROR,
    error,
  }
}
