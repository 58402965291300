import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  dictionaryProductCategory,
  dictionaryRepairRequestStatus,
  RepairRequestData,
} from '../../../requests/repair-request/repairRequest.domain'
import { TextContentLoader } from '../../common/loaders'

type Props = {
  repairRequestDetails: RepairRequestData | null
}

export const AdminRepairItemSummaryLayout = (props: Props) => {
  const { repairRequestDetails } = props
  const { t } = useTranslation()

  const details = [
    {
      name: 'repairRequest',
      text: 'Repair request ID',
      value: repairRequestDetails?.requestId,
      bold: true,
    },
    {
      name: 'serialNumber',
      text: 'Seriennummer',
      value: repairRequestDetails?.serialNumber,
      bold: false,
    },
    {
      name: 'fullName',
      text: 'Full name',
      value: repairRequestDetails?.fullName,
      bold: false,
    },
    {
      name: 'email',
      text: 'E-mail',
      value: repairRequestDetails?.email,
      bold: false,
    },
    {
      name: 'purchaseDate',
      text: 'Datum des Kaufs',
      value: repairRequestDetails?.purchaseDate
        ? new Date(repairRequestDetails?.purchaseDate).toLocaleDateString('de-DE')
        : 'N/A',
      bold: false,
    },
    {
      name: 'productCategory',
      text: 'Gerätekategorie',
      value: dictionaryProductCategory.item(repairRequestDetails?.productCategory as string)
        ? dictionaryProductCategory.item(repairRequestDetails?.productCategory as string).toString()
        : '-',
      bold: false,
    },
    {
      name: 'productModel',
      text: 'Produktbezeichnung',
      value: repairRequestDetails?.productModel,
      bold: false,
    },
    {
      name: 'requestDatetime',
      text: 'Abgabedatum',
      value: new Date(repairRequestDetails?.requestDatetime as Date).toLocaleString('de-DE'),
      bold: false,
    },
    {
      name: 'modificationDatetime',
      text: 'Änderungsdatum',
      value: new Date(repairRequestDetails?.modificationDatetime as Date).toLocaleString('de-DE'),
      bold: false,
    },
    {
      name: 'currentStatus',
      text: 'Aktueller Status',
      value: repairRequestDetails?.currentStatus
        ? t(dictionaryRepairRequestStatus.item(repairRequestDetails?.currentStatus).value)
        : 'N/A',
      bold: true,
    },
    {
      name: 'requestDescription',
      text: 'Beschreibung des Fehlers',
      value: repairRequestDetails?.requestDescription,
      bold: true,
    },
  ]

  return repairRequestDetails ? (
    <div>
      {details.map((field) => {
        return (
          <div className="flex border m-1 p-2" key={field.name}>
            <div className={`w-1/4 ${field.bold ? 'font-bold' : ''}`}>{field.text}</div>
            <div className={field.bold ? 'font-bold' : ''}>{field.value}</div>
          </div>
        )
      })}
    </div>
  ) : (
    <TextContentLoader />
  )
}
