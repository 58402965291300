const handleError = (response: Response) => {
  if (response.status === 403 || response.status === 401) {
    throw Error('Unauthorized')
  }
  if (response.status > 403 && response.status < 500) {
    throw Error(response.statusText)
  }
  if (response.status > 500) {
    throw Error('Server error: ' + response.statusText)
  } else {
    throw Error('OtherError')
  }
}

export default handleError
