import { BACKEND_URL } from '../../config'
import { headers } from '../../utils/apiHeaders'
import handleResponse from '../../utils/handleApiResponse'

export const getPriceListTypeList = (rmaCustomerId: string) => {
  const URL_API_PRICE_LIST_TYPE_LIST = BACKEND_URL + `/api/price-list/${rmaCustomerId}/list`
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }
  return fetch(URL_API_PRICE_LIST_TYPE_LIST, requestOptions)
    .then(handleResponse)
    .then((data) => data?.priceListTypeList)
    .catch((e) => {
      throw Error('Error ', e.message)
    }) as Promise<string[]>
}
