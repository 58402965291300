import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { RouteLink } from '../routes'

export const ErrorMessageBox = ({
  errorState,
  message,
}: {
  errorState: Error
  message?: string
}) => {
  const { t } = useTranslation()

  const [show, setShow] = useState(false)
  const [msg, setMessage] = useState('')

  useEffect(() => {
    if (errorState) {
      setMessage(message ? message : errorState.message)
      setShow(true)

      const errorMessageTimer = setTimeout(() => {
        setMessage('')
        setShow(false)
      }, 15000)

      return () => clearTimeout(errorMessageTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorState])

  return show ? (
    <div className="flex justify-between bg-red-200 p-2" role="alert">
      {`${msg} `}
      <Link to={`/${RouteLink.MAIN}`} onClick={() => setShow(false)}>
        <span className="text-gray-15 font-semibold">{t('common.close')}</span>
      </Link>
    </div>
  ) : null
}
