import { useEffect } from 'react'
import BarLoader from 'react-spinners/BarLoader'
import { Navigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from './hooks'

import { RouteLink } from '../views/common'
import { UserRole } from '../user/user.domain'
import { fetchUserStartAction } from '../user/user.actions'
import { JWT_USER_TOKEN } from '../config'

export const ProtectedRoute = ({
  adminRoleRequired,
  children,
}: {
  children: JSX.Element
  adminRoleRequired?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { auth: authStore, user: userStore } = useAppSelector((state) => state)
  const isUserDefined = !!userStore.userData
  const hasUserAdminRole = userStore.userData?.role === UserRole.ADMIN

  const loginInProgress = authStore.inProgress
  const hasLogged = sessionStorage.getItem(JWT_USER_TOKEN)
  const userVerificationInProgress = hasLogged && (authStore.token === null || userStore.inProgress)

  useEffect(() => {
    if (!userStore.userData && !userStore.error && !userStore.inProgress) {
      dispatch(fetchUserStartAction())
    }
  }, [userStore, dispatch])

  if (loginInProgress || userVerificationInProgress) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <BarLoader color="#C7BAA7" />
      </div>
    )
  }

  if (adminRoleRequired && !hasUserAdminRole) {
    return <Navigate to={RouteLink.MAIN} replace />
  }

  if (isUserDefined) {
    return children
  }

  return <Navigate to={`/${RouteLink.LOGIN}`} replace />
}
