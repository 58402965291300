export interface IDictionary<V> {
  add(key: string, value: V): void
  containsKey(key: string): boolean
  count(): number
  item(key: string): V
  keys(): string[]
  values(): V[]
  map<U>(callbackfn: (key: string, value: V) => U): U[]
}

export class Dictionary<V> implements IDictionary<V> {
  private items: { [index: string]: V } = {}

  private _count = 0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(init: Array<Array<any>>) {
    init.forEach((item) => {
      if (!item || item.length !== 2) {
        throw Error(`Bad argument when initializing dictionary ${item}`)
      } else {
        this.add(item[0], item[1])
      }
    })
  }

  public containsKey(key: string): boolean {
    return !!Object.getOwnPropertyDescriptor(this.items, key)
  }

  public count(): number {
    return this._count
  }

  public add(key: string, value: V) {
    if (!this.containsKey(key)) this._count++

    this.items[key] = value
  }

  public item(key: string): V {
    return this.items[key]
  }

  public keys(): string[] {
    const keySet: string[] = []

    for (const prop in this.items) {
      if (this.containsKey(prop)) {
        keySet.push(prop)
      }
    }

    return keySet
  }

  public values(): V[] {
    const values: V[] = []

    for (const prop in this.items) {
      if (this.containsKey(prop)) {
        values.push(this.items[prop])
      }
    }

    return values
  }

  public map<U>(callbackfn: (key: string, value: V) => U): U[] {
    const resultArray: unknown[] = []

    this.keys().forEach((k) => {
      resultArray.push(callbackfn(k, this.item(k)))
    })
    return resultArray as U[]
  }
}
