import { useTranslation } from 'react-i18next'

import NewPageTitle from '../common/new-page-title/NewPageTitle'
import AccordionOption from '../common/page-menu/AccordionOption'
import { Button } from '../common/button/Button'
import {
  burmesterMcIosLink,
  burmesterComposeIosLink,
  burmesterComposeAndroidLink,
  soundcheckAppMercedesIosLink,
  soundcheckAppMercedesAndroidLink,
  soundcheckAppPorscheIosLink,
  soundcheckAppPorscheAndroidLink,
  iosAppleStore,
  androidAppStore,
} from '../../constants'

export const BurmesterApp = () => {
  const { t } = useTranslation()

  const handleDownloadButton = (link: string) => {
    window.open(link, '_blank')
  }
  return (
    <div className="pt-8 pb-8">
      <NewPageTitle topText={t('burmesterApp.topText')} title={t('burmesterApp.title')} backButton>
        {t('burmesterApp.pageDescription')}
      </NewPageTitle>
      <div className="flex flex-col items-center pb-40">
        <AccordionOption title={t('burmesterApp.mc')} marginBottom>
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{iosAppleStore}</div>
            <Button onClick={() => handleDownloadButton(burmesterMcIosLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
        </AccordionOption>

        <AccordionOption title={t('burmesterApp.compose')} marginBottom>
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{iosAppleStore}</div>
            <Button onClick={() => handleDownloadButton(burmesterComposeIosLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
          <div className="border-t border-solid border-gray-10 my-8" />
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{androidAppStore}</div>
            <Button onClick={() => handleDownloadButton(burmesterComposeAndroidLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
        </AccordionOption>

        <AccordionOption title={t('burmesterApp.soundCheckMercedes')} marginBottom>
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{iosAppleStore}</div>
            <Button onClick={() => handleDownloadButton(soundcheckAppMercedesIosLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
          <div className="border-t border-solid border-gray-10 my-8" />
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{androidAppStore}</div>
            <Button onClick={() => handleDownloadButton(soundcheckAppMercedesAndroidLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
        </AccordionOption>
        <AccordionOption title={t('burmesterApp.soundCheckPorsche')} marginBottom>
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{iosAppleStore}</div>
            <Button onClick={() => handleDownloadButton(soundcheckAppPorscheIosLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
          <div className="border-t border-solid border-gray-10 my-8" />
          <div className="w-full flex justify-between px-8 items-center">
            <div className="text-xs uppercase text-gray-15">{androidAppStore}</div>
            <Button onClick={() => handleDownloadButton(soundcheckAppPorscheAndroidLink)}>
              {t('burmesterApp.menu.downloadButton')}
            </Button>
          </div>
        </AccordionOption>
      </div>
    </div>
  )
}
