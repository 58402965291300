import React, { useEffect } from 'react'

interface DialogProps {
  children: React.ReactNode
  setIsOpen: (isOpen: boolean) => void
  rmaCustomerId?: string
  setRmaCustomerIdValue?: (rmaCustomerId: string) => void
  title?: string
}

export const Dialog = ({
  setIsOpen,
  children,
  title,
  rmaCustomerId = '',
  setRmaCustomerIdValue,
}: DialogProps) => {
  useEffect(() => {
    if (rmaCustomerId && setRmaCustomerIdValue) {
      setRmaCustomerIdValue(rmaCustomerId)
    }
  }, [rmaCustomerId, setRmaCustomerIdValue])

  const closeDialog = () => setIsOpen(false)

  return (
    <div className="flex items-center justify-center w-full">
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-lg sm:w-3/4 md:w-2/3 lg:w-5/12 xl:w-1/3">
          <div className="flex justify-between items-center mb-4 ">
            <h2 className="text-xl font-bold">{title}</h2>
            <button
              onClick={closeDialog}
              type="button"
              className="text-gray-400 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Dialog
