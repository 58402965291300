import { Action } from 'redux'

import { RepairRequestData, RepairRequestForm } from './repairRequest.domain'

export const REPAIR_REQUEST_FETCH_LIST_START = 'REPAIR_REQUEST/FETCH_LIST_START'
export const REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS = 'REPAIR_REQUEST/FETCH_LIST_IN_PROGRESS'
export const REPAIR_REQUEST_FETCH_LIST_DONE = 'REPAIR_REQUEST/FETCH_LIST_DONE'
export const REPAIR_REQUEST_FETCH_LIST_ERROR = 'REPAIR_REQUEST/FETCH_LIST_ERROR'

export const REPAIR_REQUEST_CREATE_START = 'REPAIR_REQUEST/CREATE_START'
export const REPAIR_REQUEST_CREATE_IN_PROGRESS = 'REPAIR_REQUEST/CREATE_IN_PROGRESS'
export const REPAIR_REQUEST_CREATE_DONE = 'REPAIR_REQUEST/CREATE_DONE'
export const REPAIR_REQUEST_CREATE_ERROR = 'REPAIR_REQUEST/CREATE_ERROR'

export const REPAIR_REQUEST_FETCH_DETAILS_START = 'REPAIR_REQUEST/FETCH_DETAILS_START'
export const REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS = 'REPAIR_REQUEST/FETCH_DETAILS_IN_PROGRESS'
export const REPAIR_REQUEST_FETCH_DETAILS_DONE = 'REPAIR_REQUEST/FETCH_DETAILS_DONE'
export const REPAIR_REQUEST_FETCH_DETAILS_ERROR = 'REPAIR_REQUEST/FETCH_DETAILS_ERROR'

// list
export interface IFetchRepairRequestsListStartAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_LIST_START
}
export const fetchRepairRequestsListStart = (): IFetchRepairRequestsListStartAction => {
  return {
    type: REPAIR_REQUEST_FETCH_LIST_START,
  }
}

export interface IFetchRepairRequestsListInProgressAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS
}
export const fetchRepairRequestsListInProgress = (): IFetchRepairRequestsListInProgressAction => {
  return {
    type: REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS,
  }
}

export interface IFetchRepairRequestsListDoneAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_LIST_DONE
  requestDataList: Array<RepairRequestData>
}
export const fetchRepairRequestsListDone = (
  requestDataList: Array<RepairRequestData>
): IFetchRepairRequestsListDoneAction => {
  return {
    type: REPAIR_REQUEST_FETCH_LIST_DONE,
    requestDataList,
  }
}

export interface IFetchRepairRequestsListErrorAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_LIST_ERROR
  error: Error
}
export const fetchRepairRequestsListError = (error: Error): IFetchRepairRequestsListErrorAction => {
  return {
    type: REPAIR_REQUEST_FETCH_LIST_ERROR,
    error,
  }
}

// creation
export interface ICreateRepairRequestStartAction extends Action {
  type: typeof REPAIR_REQUEST_CREATE_START
  repairRequestForm: RepairRequestForm
}
export const createRepairRequestStart = (
  repairRequestForm: RepairRequestForm
): ICreateRepairRequestStartAction => {
  return {
    type: REPAIR_REQUEST_CREATE_START,
    repairRequestForm,
  }
}

export interface ICreateRepairRequestInProgressAction extends Action {
  type: typeof REPAIR_REQUEST_CREATE_IN_PROGRESS
}
export const createRepairRequestInProgress = (): ICreateRepairRequestInProgressAction => {
  return {
    type: REPAIR_REQUEST_CREATE_IN_PROGRESS,
  }
}

export interface ICreateRepairRequestDoneAction extends Action {
  type: typeof REPAIR_REQUEST_CREATE_DONE
  repairRequestData: RepairRequestData
}
export const createRepairRequestsDone = (
  repairRequestData: RepairRequestData
): ICreateRepairRequestDoneAction => {
  return {
    type: REPAIR_REQUEST_CREATE_DONE,
    repairRequestData,
  }
}

export interface ICreateRepairRequestErrorAction extends Action {
  type: typeof REPAIR_REQUEST_CREATE_ERROR
  error: Error
}
export const createRepairRequestError = (error: Error): ICreateRepairRequestErrorAction => {
  return {
    type: REPAIR_REQUEST_CREATE_ERROR,
    error,
  }
}

// details
export interface IFetchRepairRequestDetailsStartAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_DETAILS_START
  repairRequestId: string
}
export const fetchRepairRequestDetailsStart = (
  repairRequestId: string
): IFetchRepairRequestDetailsStartAction => {
  return {
    type: REPAIR_REQUEST_FETCH_DETAILS_START,
    repairRequestId,
  }
}

export interface IFetchRepairRequestDetailsInProgressAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS
}
export const fetchRepairRequestDetailsInProgress =
  (): IFetchRepairRequestDetailsInProgressAction => {
    return {
      type: REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS,
    }
  }

export interface IFetchRepairRequestDetailsDoneAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_DETAILS_DONE
  requestData: RepairRequestData
}
export const fetchRepairRequestDetailsDone = (
  requestData: RepairRequestData
): IFetchRepairRequestDetailsDoneAction => {
  return {
    type: REPAIR_REQUEST_FETCH_DETAILS_DONE,
    requestData,
  }
}

export interface IFetchRepairRequestDetailsErrorAction extends Action {
  type: typeof REPAIR_REQUEST_FETCH_DETAILS_ERROR
  error: Error
}
export const fetchRepairRequestDetailsError = (
  error: Error
): IFetchRepairRequestDetailsErrorAction => {
  return {
    type: REPAIR_REQUEST_FETCH_DETAILS_ERROR,
    error,
  }
}
