import { headers } from '../../../utils/apiHeaders'
import handleResponse from '../../../utils/handleApiResponse'
import handleError from '../../../utils/handleApiError'
import { URL_ADMIN_API_USERS } from '../adminUserManagement.service'

export const resetPasswordAdminUser = (username: string) => {
  const requestOptions = {
    method: 'PATCH',
    headers: headers(),
  }
  return fetch(`${URL_ADMIN_API_USERS}/${username}/resetPassword`, requestOptions)
    .then(handleResponse)
    .then((password) => password)
    .catch(handleError)
}
