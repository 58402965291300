const handleResponse = (response: Response) => {
  if (response.ok) {
    return response.json()
  } else if (response.status === 403 || response.status === 401) {
    throw Error('Unauthorized')
  } else {
    throw Error('OtherError')
  }
}

export default handleResponse
