import { Action } from 'redux'

import { GuaranteeType } from './adminGuarantees.domain'

export const ADMIN_GET_GUARANTEES_LIST = 'ADMIN/GUARANTEES_LIST/GET_GUAEANTEES_LIST'
export const ADMIN_SET_GUARANTEES_LIST = 'ADMIN/GUARANTEES_LIST/SET_GUAEANTEES_LIST'

export interface IGetGuaranteesListAction extends Action {
  type: typeof ADMIN_GET_GUARANTEES_LIST
  requestData: string
}

export const GetAdminGuaranteesList = (requestData: string): IGetGuaranteesListAction => {
  return {
    type: ADMIN_GET_GUARANTEES_LIST,
    requestData,
  }
}

export interface ISetGuaranteesListAction extends Action {
  type: typeof ADMIN_SET_GUARANTEES_LIST
  requestData: GuaranteeType[]
}

export const SetAdminGuaranteesList = (requestData: GuaranteeType[]): ISetGuaranteesListAction => {
  return {
    type: ADMIN_SET_GUARANTEES_LIST,
    requestData,
  }
}
