import { Action } from 'redux'

import { CustomerRmaType } from './customer.domain'

export const CUSTOMER_RMA_FETCH_START = 'CUSTOMER_RMA/FETCH_START'
export const CUSTOMER_RMA_FETCH_IN_PROGRESS = 'CUSTOMER_RMA/FETCH_IN_PROGRESS'
export const CUSTOMER_RMA_FETCH_DONE = 'CUSTOMER_RMA/FETCH_DONE'
export const CUSTOMER_RMA_FETCH_ERROR = 'CUSTOMER_RMA/FETCH_ERROR'

export interface ICustomerFetchStartAction extends Action {
  type: typeof CUSTOMER_RMA_FETCH_START
  userId: string
}
export const fetchCustomerStartAction = (userId: string): ICustomerFetchStartAction => {
  return {
    type: CUSTOMER_RMA_FETCH_START,
    userId: userId,
  }
}

export interface ICustomerFetchInProgressAction extends Action {
  type: typeof CUSTOMER_RMA_FETCH_IN_PROGRESS
  userId: string
}
export const fetchUserActionInProgress = (userId: string): ICustomerFetchInProgressAction => {
  return {
    type: CUSTOMER_RMA_FETCH_IN_PROGRESS,
    userId: userId,
  }
}

export interface ICustomerFetchDoneAction extends Action {
  type: typeof CUSTOMER_RMA_FETCH_DONE
  rmaData: CustomerRmaType[]
}
export const fetchUserActionDone = (rmaData: CustomerRmaType[]): ICustomerFetchDoneAction => {
  return {
    type: CUSTOMER_RMA_FETCH_DONE,
    rmaData,
  }
}

export interface ICustomerFetchErrorAction extends Action {
  type: typeof CUSTOMER_RMA_FETCH_ERROR
  error: Error
}
export const createCustomerActionError = (error: Error): ICustomerFetchErrorAction => {
  return {
    type: CUSTOMER_RMA_FETCH_ERROR,
    error,
  }
}
