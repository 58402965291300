import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

import { Button } from '../button/Button'
import { RouteLink } from '../routes'

const NewPageTitle = ({
  backButton,
  children,
  title,
  topText,
  navigateToMainPage = false,
}: {
  backButton?: boolean
  children?: React.ReactNode
  navigateToMainPage?: boolean
  title: string
  topText?: string
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goBack = (navigateToMainPage: boolean) =>
    navigateToMainPage ? navigate(RouteLink.MAIN) : navigate(-1)

  return (
    <div className="pb-13 text-center">
      {backButton && (
        <div className="text-left lg:relative">
          <Button className="mb-6 lg:absolute lg:left-0" onClick={() => goBack(navigateToMainPage)}>
            <div className="flex">
              <div className="mr-2 -ml-2">
                <ChevronLeftIcon className="h-4 w-4" />
              </div>
              <div className="font-normal tracking-wider text-gray-15">
                {t('common.backToOverview')}
              </div>
            </div>
          </Button>
        </div>
      )}
      <h3 className="font-avenir text-md text-gray tracking-widest uppercase mb-3">{topText}</h3>
      <h1 className="font-sabon text-5xl text-gray-10 font-semibold tracking-wider">{title}</h1>
      <div className="flex justify-center mt-12 pt-1 mb-12">
        <p className="font-untitled-sans text-lg w-[46rem] text-gray-10 text-center">{children}</p>
      </div>
    </div>
  )
}

export default NewPageTitle
