import { put, call, takeLatest } from 'redux-saga/effects'

import {
  fetchUserDoneAction,
  fetchUserErrorAction,
  fetchUserInProgressAction,
  USER_FETCH_START,
} from './user.actions'
import { fetchCurrentUser } from './user.service'
import { UserData } from './user.domain'

import { authUserDoneAction, authUserLogoutAction } from '../auth/auth.actions'
import { JWT_USER_TOKEN } from '../config'

export function* watchForFetchUserStartAction() {
  yield takeLatest(USER_FETCH_START, callFetchUser)
}

function* callFetchUser() {
  try {
    yield put(fetchUserInProgressAction())
    const response = (yield call(fetchCurrentUser)) as UserData
    yield put(fetchUserDoneAction(response))
    yield put(authUserDoneAction(sessionStorage.getItem(JWT_USER_TOKEN) || ''))
  } catch (error: unknown) {
    yield put(fetchUserErrorAction(error as Error))
    yield put(authUserLogoutAction())
  }
}
