import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

import { Button } from '../common/button/Button'
import NewPageTitle from '../common/new-page-title/NewPageTitle'

export const RepairRequestThankYouPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goBack = () => navigate('/reparatur')

  return (
    <div className={'pb-20 pt-8 min-h-fit'}>
      <div className="flex">
        <Button className="mb-6" onClick={goBack}>
          <div className="flex">
            <div className="mr-2 -ml-2">
              <ChevronLeftIcon className="h-4 w-4" />
            </div>
            <div className="font-normal tracking-wider text-gray-15">
              {t('common.backToOverview')}
            </div>
          </div>
        </Button>
      </div>
      <div className="flex justify-center">
        <NewPageTitle
          topText={t('repairRequest.thankYou.topText')}
          title={t('repairRequest.thankYou.title')}
        >
          {t('repairRequest.thankYou.pageDescription')}
        </NewPageTitle>
      </div>
    </div>
  )
}
