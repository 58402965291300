import { AdminUserData } from '../../../user/user.domain'
import { headers } from '../../../utils/apiHeaders'
import handleError from '../../../utils/handleApiError'
import handleResponse from '../../../utils/handleApiResponse'
import { URL_ADMIN_API_USERS } from '../adminUserManagement.service'

export const fetchAdminUser = (username: string) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(`${URL_ADMIN_API_USERS}/${username}`, requestOptions)
    .then(handleResponse)
    .then((user) => user)
}

export const patchAdminUser = (username: string, data: AdminUserData) => {
  const requestOptions = {
    method: 'PATCH',
    headers: headers(),
    body: JSON.stringify({
      fullName: data.fullName,
      email: data.email,
      phone: data.phone,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      postcode: data.postcode,
      city: data.city,
      country: data.country,
      role: data.role,
      rmaCustomerId: data.rmaCustomerId,
    }),
  }

  return fetch(`${URL_ADMIN_API_USERS}/${username}`, requestOptions)
    .then(handleResponse)
    .then((user) => user)
}

export const deleteAdminUser = (username: string) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(),
  }

  return fetch(`${URL_ADMIN_API_USERS}/${username}`, requestOptions).catch(handleError)
}
