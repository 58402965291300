import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../utils/hooks'
import NewPageTitle from '../common/new-page-title/NewPageTitle'

export const UserProfilePage = () => {
  const { t } = useTranslation()
  const { userData } = useAppSelector((state) => state.user)
  return (
    <div>
      <NewPageTitle topText={t('profile.topText')} title={t('profile.title')} />
      <div className="flex justify-center">
        <div className="w-full lg:w-1/2">
          <div className="flex flex-col pb-20">
            <span className="text-xs tracking-wider font-bold mb-10">
              {t('profile.generalInformations')}
            </span>
            <input
              className="mb-10 border-b border-gray-10"
              id="company"
              placeholder={t('profile.fields.company') as string}
              disabled
            />
            <input
              className="mb-10 border-b border-gray-10"
              id="contactPerson"
              placeholder={t('profile.fields.contactPerson') as string}
              disabled
            />
            <input
              className="mb-10 border-b border-gray-10"
              id="phoneNumber"
              placeholder={t('profile.fields.phoneNumber') as string}
              value={userData?.phone}
              disabled
            />
            <input
              className="mb-10 border-b border-gray-10"
              id="emailAddress"
              placeholder={t('profile.fields.emailAddress') as string}
              value={userData?.email}
              disabled
            />
            <input
              className="mb-10 border-b border-gray-10"
              id="website"
              placeholder={t('profile.fields.website') as string}
              disabled
            />
            <span className="text-xs tracking-wider font-bold mb-10">{t('profile.account')}</span>
            <input
              className="mb-10 border-b border-gray-10"
              id="username"
              placeholder={t('profile.fields.username') as string}
              value={userData?.username}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}
