import { put, call, takeLatest } from 'redux-saga/effects'

import { getGuaranteesListService } from './adminGuarantees.service'
import * as Actions from './adminGuarantees.action'
import { GuaranteeType } from './adminGuarantees.domain'

export function* getGuarantees() {
  yield takeLatest(Actions.ADMIN_GET_GUARANTEES_LIST, getGuaranteesListSaga)
}

function* getGuaranteesListSaga(action: Actions.IGetGuaranteesListAction) {
  try {
    const response: GuaranteeType[] = yield call(getGuaranteesListService, action.requestData)
    yield put(Actions.SetAdminGuaranteesList(response))
  } catch (error) {
    // yield put(authUserLogoutAction());
  }
}
