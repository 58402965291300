import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AdminRepairItemSummaryLayout } from './AdminRepairItemSummaryLayout'

import { PageTitle, RouteLink } from '../../common'
import {
  dictionaryRepairRequestStatus,
  RepairRequestData,
  RepairRequestStatus,
  RepairRequestStatusForm,
} from '../../../requests/repair-request/repairRequest.domain'
import {
  deleteAdminRepairRequestStart,
  fetchAdminRepairRequestDetailsStart,
  putAdminRepairRequestStatusStart,
} from '../../../admin/repair-request/adminRepairRequest.action'
import { RepairRequestStatusTree } from '../../repair-request/RepairRequestStatusTree'
import { MENU_ITEM } from '../../../admin/menu/constants'
import { HorizontalAdminMenu } from '../../../admin/menu/HorizontalAdminMenu'
import { ErrorMessageBox } from '../../common/messages/ErrorMessageBox'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { Button } from '../../common/button/Button'

export const AdminRepairItemEditPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const adminRepairRequestStore = useAppSelector((state) => state.adminRepairRequest)
  const [repairRequestDetails, setRepairRequestDetails] = useState<RepairRequestData | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<RepairRequestStatus>(
    RepairRequestStatus.DATA_SENT
  )
  const { id = '' } = useParams<{ id: string }>()
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    if (id) {
      dispatch(fetchAdminRepairRequestDetailsStart(id))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (adminRepairRequestStore && adminRepairRequestStore.details) {
      setRepairRequestDetails(adminRepairRequestStore.details)
    } else {
      if (repairRequestDetails && repairRequestDetails.currentStatus) {
        setSelectedStatus(repairRequestDetails?.currentStatus)
      }
    }
  }, [adminRepairRequestStore, repairRequestDetails])

  useEffect(() => {
    if (repairRequestDetails?.currentStatus) {
      setSelectedStatus(repairRequestDetails?.currentStatus)
    }
  }, [repairRequestDetails])

  const statusChange = (newStatus: string) => {
    setSelectedStatus(RepairRequestStatus[newStatus as keyof typeof RepairRequestStatus])
  }

  const onSubmit = (data: RepairRequestStatusForm) => {
    dispatch(putAdminRepairRequestStatusStart(id, data))
    navigate(RouteLink.ADMIN_REQUEST_SERVICE + `/${id}`)
  }

  const handleDeleteRequestButton = () => {
    if (!adminRepairRequestStore?.details?.requestId) return
    const answer = window.confirm('Möchten Sie diese Anfrage löschen?')
    answer && dispatch(deleteAdminRepairRequestStart(adminRepairRequestStore.details.requestId))
  }

  return (
    <div className="flex justify-center">
      <div className="w-3/4 px-4 bg-stone-100 border-l border-r border-stone-300">
        <HorizontalAdminMenu current={MENU_ITEM.REPAIR_LIST} />
        <div>
          <div>
            <Link to={RouteLink.ADMIN_REQUEST_SERVICE}>‹‹ {t('common.back')}</Link>
          </div>
          <div className="my-10">
            <div className="flex justify-between">
              <PageTitle value="Admin mode - Repair edit" />
              {adminRepairRequestStore?.details && (
                <button
                  className="button button-danger"
                  style={{ fontSize: '10px' }}
                  onClick={handleDeleteRequestButton}
                >
                  <div className="d-flex align-items-center align-content-stretch">
                    <span>Delete</span>
                  </div>
                </button>
              )}
            </div>
          </div>

          <AdminRepairItemSummaryLayout repairRequestDetails={repairRequestDetails} />

          <div className="border m-1 p-2">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex mt-2">
                <div className="w-1/2 mr-1">
                  <div className="flex flex-col">
                    <label className="font-bold" htmlFor="type">
                      Status aktualisieren *
                    </label>
                    <select
                      className="p-3 my-2"
                      ref={register({ required: true })}
                      name={'type'}
                      value={selectedStatus}
                      onChange={(e) => statusChange(e.target.value)}
                    >
                      {dictionaryRepairRequestStatus.map((k, v) => (
                        <option value={k} key={'requestStatus-option-' + k}>
                          {t(v.value)}
                        </option>
                      ))}
                    </select>
                    <span className="text-xs">
                      {t(dictionaryRepairRequestStatus.item(selectedStatus).description as string)}
                    </span>
                    {errors.type && (
                      <div className="text-danger mt-2">Bitte auswählen neue Status</div>
                    )}
                  </div>
                </div>
                <div className="w-1/2 ml-1">
                  <div className="flex flex-col">
                    <label className="font-bold pl-4" htmlFor="file">
                      Dokument
                    </label>
                    <input className="p-3 my-2" ref={register} type="file" name="file" />
                  </div>
                </div>
              </div>
              <div className="flex mt-10">
                <div className="w-1/2 mr-1">
                  <div className="flex flex-col">
                    <label className="font-bold" htmlFor="additionalInfo">
                      Zusätzliche Informationen
                    </label>
                    <input className="p-3 my-2" ref={register} type="text" name="additionalInfo" />
                  </div>
                </div>
                <div className="w-1/2 ml-1">
                  <div className="flex flex-col">
                    <label className="font-bold" htmlFor="additionalInfo">
                      Link zum zusätzliche Informationen
                    </label>
                    <input
                      className="p-3 my-2"
                      ref={register}
                      type="text"
                      name="additionalInfoLink"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row mt-5">
                  <div className="col">
                    <Button type="submit">Update status</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {adminRepairRequestStore.error && (
            <ErrorMessageBox errorState={adminRepairRequestStore.error} />
          )}
          <RepairRequestStatusTree
            adminMode={true}
            hideInvoiceFile={false}
            repairRequest={repairRequestDetails}
          />
        </div>
      </div>
    </div>
  )
}
