import React from 'react'
import { Link } from 'react-router-dom'
import './HorizontalMenu.scss'

type Props = {
  isActive: boolean
  title: string
  link: string
}

export const HorizontalMenuItem = (props: Props) => {
  return (
    <Link
      to={props.link}
      className={
        'horizontal-menu-item fw-400 dark-grey ' +
        (props.isActive ? 'horizontal-menu-item-active' : '')
      }
    >
      {props.title}
    </Link>
  )
}
