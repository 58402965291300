import usePrevious from './usePrevious'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePreviousCompare = (value: any) => {
  const prevValue = usePrevious(value)

  if (value === prevValue) return false
  if (!(value instanceof Object) || !(prevValue instanceof Object)) return true

  const keys = Object.keys(value)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    if (!(keys[i] in prevValue)) return true
  }

  for (let i = 0; i < length; i += 1) {
    if (value[keys[i]] !== prevValue[keys[i]]) return true
  }

  return length !== Object.keys(prevValue).length
}

export default usePreviousCompare
