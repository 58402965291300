import { Action } from 'redux'

import { AdminUserData } from '../../user/user.domain'

export const ADMIN_CREATE_USER_START = 'ADMIN/USER_MANAGEMENT/CREATE_USER_START'
export const ADMIN_CREATE_USER_IN_PROGRESS = 'ADMIN/USER_MANAGEMENT/CREATE_USER_IN_PROGRESS'
export const ADMIN_CREATE_USER_DONE = 'ADMIN/USER_MANAGEMENT/CREATE_USER_DONE'
export const ADMIN_CREATE_USER_ERROR = 'ADMIN/USER_MANAGEMENT/CREATE_USER_ERROR'
export const ADMIN_CLEAN_CREATED_USER = 'ADMIN/USER_MANAGEMENT/CLEAN_CREATED_USER'

export interface IAdminUserCreateStartAction extends Action {
  type: typeof ADMIN_CREATE_USER_START
  userData: AdminUserData
}
export const createUserActionStart = (userData: AdminUserData): IAdminUserCreateStartAction => {
  return {
    type: ADMIN_CREATE_USER_START,
    userData,
  }
}

export interface IAdminUserCreateInProgressAction extends Action {
  type: typeof ADMIN_CREATE_USER_IN_PROGRESS
}
export const createUserActionInProgress = (): IAdminUserCreateInProgressAction => {
  return {
    type: ADMIN_CREATE_USER_IN_PROGRESS,
  }
}

export interface IAdminUserCreateDoneAction extends Action {
  type: typeof ADMIN_CREATE_USER_DONE
  userData: AdminUserData
}
export const createUserActionDone = (userData: AdminUserData): IAdminUserCreateDoneAction => {
  return {
    type: ADMIN_CREATE_USER_DONE,
    userData,
  }
}

export interface IAdminUserCreateErrorAction extends Action {
  type: typeof ADMIN_CREATE_USER_ERROR
  error: Error
}
export const createUserActionError = (error: Error): IAdminUserCreateErrorAction => {
  return {
    type: ADMIN_CREATE_USER_ERROR,
    error,
  }
}

export interface IAdminCleanCreatedUserAction extends Action {
  type: typeof ADMIN_CLEAN_CREATED_USER
}
export const cleanCreatedUserAction = (): IAdminCleanCreatedUserAction => {
  return {
    type: ADMIN_CLEAN_CREATED_USER,
  }
}
