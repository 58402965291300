import querystring from 'querystring'

import { AdminRepairRequestsFilter } from './adminRepairRequest.domain'

import {
  RepairRequestStatus,
  RepairRequestStatusData,
  RepairRequestStatusForm,
} from '../../requests/repair-request/repairRequest.domain'
import handleResponse from '../../utils/handleApiResponse'
import { BACKEND_URL } from '../../config'
import { headers, noContentTypeHeaders } from '../../utils/apiHeaders'
import { downloadFile } from '../../requests/repair-request/repairRequest.service'
import handleError from '../../utils/handleApiError'

const URL_ADMIN_API_REPAIR_REQUEST = BACKEND_URL + '/api/admin/repair-requests'
const URL_ADMIN_API_REPAIR_REQUEST_LIST = URL_ADMIN_API_REPAIR_REQUEST + '/list'
const URL_ADMIN_API_REPAIR_REQUEST_DETAILS = URL_ADMIN_API_REPAIR_REQUEST + '/details/'

const serializeFilters = (filter: Record<string, string>) => querystring.stringify(filter)

export const getRepairRequests = (filter: AdminRepairRequestsFilter) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }
  return fetch(URL_ADMIN_API_REPAIR_REQUEST_LIST + '?' + serializeFilters(filter), requestOptions)
    .then(handleResponse)
    .then((requestsList) => requestsList)
    .catch(handleError)
}

export const getRepairRequestDetails = (repairRequestId: string) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(URL_ADMIN_API_REPAIR_REQUEST_DETAILS + repairRequestId, requestOptions)
    .then(handleResponse)
    .then((requestDetails) => requestDetails)
    .catch(handleError)
}

export const putRepairRequestStatus = (
  repairRequestId: string,
  repairRequestStatusData: RepairRequestStatusData,
  documentFile?: FileList
) => {
  const formData = new FormData()
  formData.append('statusData', JSON.stringify(repairRequestStatusData))

  if (documentFile && documentFile.length > 0) {
    formData.append('documentFile', documentFile[0])
  }

  const requestOptions = {
    method: 'PUT',
    headers: noContentTypeHeaders(),
    body: formData,
  }

  return fetch(URL_ADMIN_API_REPAIR_REQUEST + '/' + repairRequestId, requestOptions)
    .then(handleResponse)
    .then((createdRepairRequest) => createdRepairRequest)
    .catch(handleError)
}

export const downloadAdminFile = (
  requestId: string,
  statusId: number,
  fileName: string,
  index: number
) => downloadFile(requestId, statusId, fileName, index, URL_ADMIN_API_REPAIR_REQUEST)

export const convertRepairRequestStatusFormToData = (
  form: RepairRequestStatusForm
): RepairRequestStatusData => {
  return {
    type: RepairRequestStatus[form.type as keyof typeof RepairRequestStatus],
    additionalInfo: form.additionalInfo,
    additionalInfoLink: form.additionalInfoLink,
  }
}

export const deleteRepairRequest = (repairRequestId: string) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(),
  }

  return fetch(`${URL_ADMIN_API_REPAIR_REQUEST}/${repairRequestId}`, requestOptions).catch(
    handleError
  )
}
