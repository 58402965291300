import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AdminUserForm } from './AdminUserForm'

import { PageTitle, RouteLink } from '../../common'
import { AdminUserData } from '../../../user/user.domain'
import {
  cleanCreatedUserAction,
  createUserActionStart,
} from '../../../admin/user-management/adminUserManagement.action'
import { HorizontalAdminMenu } from '../../../admin/menu/HorizontalAdminMenu'
import { MENU_ITEM } from '../../../admin/menu/constants'
import { AdminUserManagementStateType } from '../../../admin/user-management/adminUserManagement.reducer'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'

export const AdminUserCreationPage = () => {
  const dispatch = useAppDispatch()
  const adminUserManagementStore: AdminUserManagementStateType = useAppSelector(
    (state) => state.adminUserManagement
  )
  const [createdPassword, setCreatedPassword] = useState<string | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createdError, setCreatedError] = useState<string | null>(null)
  const methods = useForm()
  const { reset } = methods
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(cleanCreatedUserAction())
  }, [dispatch])

  useEffect(() => {
    if (adminUserManagementStore && adminUserManagementStore?.newUser?.password) {
      setCreatedPassword(adminUserManagementStore.newUser.password)
    }
  }, [adminUserManagementStore, createdPassword])

  useEffect(() => {
    if (adminUserManagementStore && adminUserManagementStore.error) {
      setCreatedError(adminUserManagementStore.error.message)
    }
  }, [adminUserManagementStore, createdPassword])

  const handleCreateNewUser = () => {
    setCreatedPassword(null)
    reset()
    dispatch(cleanCreatedUserAction())
  }

  const onSubmit = (data: AdminUserData) => {
    dispatch(createUserActionStart(data))
  }

  return (
    <div className="flex justify-center">
      <div className="w-3/4 px-4 bg-stone-100 border-l border-r border-stone-300">
        <HorizontalAdminMenu current={MENU_ITEM.USERS} />
        <div className="container">
          <div className="col-12">
            <div className="row mb-5">
              <Link to={RouteLink.ADMIN_USERS}>‹‹ {t('common.back')}</Link>
            </div>
          </div>
        </div>
        <div className="container">
          <PageTitle value="Admin mode - Create user" />
          <FormProvider {...methods}>
            <AdminUserForm
              submitButtonLabel={t('adminUserForm.create')}
              createdPassword={createdPassword}
              onClick={handleCreateNewUser}
              onSubmit={onSubmit}
            />
          </FormProvider>
        </div>
      </div>
    </div>
  )
}
