import downloadjs from 'downloadjs'

import { RepairRequestData, RepairRequestForm } from './repairRequest.domain'

import { headers, noContentTypeHeaders } from '../../utils/apiHeaders'
import { BACKEND_URL } from '../../config'
import handleResponse from '../../utils/handleApiResponse'

const URL_API_REPAIR_REQUEST = BACKEND_URL + '/api/repair-requests'
const URL_API_REPAIR_REQUEST_LIST = URL_API_REPAIR_REQUEST + '/list'

export const getRepairRequests = () => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }
  return fetch(URL_API_REPAIR_REQUEST_LIST, requestOptions)
    .then(handleResponse)
    .then((requestsList) => requestsList)
}

export const postRepairRequests = (data: RepairRequestData, invoice?: FileList) => {
  const formData = new FormData()
  formData.append('requestData', JSON.stringify(data))

  if (invoice && invoice.length > 0) {
    for (let i = 0; i < invoice.length; i += 1) {
      formData.append('invoice', invoice[i], invoice[i].name)
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: noContentTypeHeaders(),
    body: formData,
  }

  return fetch(URL_API_REPAIR_REQUEST, requestOptions)
    .then(handleResponse)
    .then((createdRepairRequest) => createdRepairRequest)
}

export const getRepairRequestDetails = (repairRequestId: string) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(`${URL_API_REPAIR_REQUEST}/${repairRequestId}`, requestOptions)
    .then(handleResponse)
    .then((requestDetails) => requestDetails)
}

export const downloadFile = (
  requestId: string,
  statusId: number,
  fileName: string,
  index: number,
  apiContext = URL_API_REPAIR_REQUEST
) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }
  return fetch(`${apiContext}/${requestId}/download/${statusId}/${index}`, requestOptions)
    .then((resp) => resp.blob())
    .then((blob) => downloadjs(blob, fileName, 'application/octet-stream'))
}

export const convertRepairRequestFormToData = (form: RepairRequestForm): RepairRequestData => {
  const { accessories, accessoriesOtherDescription } = form
  return {
    requestId: form.requestId,
    serialNumber: form.serialNumber,
    purchaseDate: form.purchaseDate ? form.purchaseDate.toISOString().split('T')[0] : '',
    productModel: form.productModel,
    productCategory: form.productCategory,
    newPackaging: form.newPackaging === 1,
    requestDescription: form.requestDescription,
    paymentAgreement: form.paymentAgreement,
    rightsAgreement: form.rightsAgreement,
    versionSoftware: form.versionSoftware,
    // warrantyClaim: form.warrantyClaim,
    accessories: `${accessories.join(',')}${
      accessoriesOtherDescription ? ':' + accessoriesOtherDescription : ''
    }`,
    // currentStatus: form.currentStatus,
    // statuses: form.statuses,
    // requestDatetime: form.requestDatetime,
    // modificationDatetime: form.modificationDatetime,
  }
}
