import { AnyAction, Reducer } from 'redux'

import {
  REPAIR_REQUEST_CREATE_DONE,
  REPAIR_REQUEST_CREATE_ERROR,
  REPAIR_REQUEST_CREATE_IN_PROGRESS,
  REPAIR_REQUEST_FETCH_DETAILS_DONE,
  REPAIR_REQUEST_FETCH_DETAILS_ERROR,
  REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS,
  REPAIR_REQUEST_FETCH_LIST_DONE,
  REPAIR_REQUEST_FETCH_LIST_ERROR,
  REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS,
} from './repairRequest.action'
import { RepairRequestData } from './repairRequest.domain'

type RepairRequestStateType = {
  list: RepairRequestData[]
  details: RepairRequestData | null
  created: RepairRequestData | null
  inProgress: boolean
  error: Error | null
}

const initialState: RepairRequestStateType = {
  list: [],
  inProgress: false,
  details: null,
  created: null,
  error: null,
}

const reducer: Reducer<RepairRequestStateType, AnyAction> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case REPAIR_REQUEST_FETCH_LIST_DONE:
      return {
        ...state,
        inProgress: false,
        list: action.requestDataList,
      }

    case REPAIR_REQUEST_FETCH_LIST_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      }

    case REPAIR_REQUEST_CREATE_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case REPAIR_REQUEST_CREATE_DONE:
      return {
        ...state,
        inProgress: false,
        created: action.repairRequestData,
      }

    case REPAIR_REQUEST_CREATE_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      }

    case REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case REPAIR_REQUEST_FETCH_DETAILS_DONE:
      return {
        ...state,
        inProgress: false,
        details: action.requestData,
      }

    case REPAIR_REQUEST_FETCH_DETAILS_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      }

    default:
      return state
  }
}
export default reducer
