import { useEffect, useState, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import { Option } from './option/Option'
const AccordionOption = ({
  children,
  title,
  marginBottom,
  smallTopMargin,
  smallBottomMargin,
}: {
  children: React.ReactNode
  title: string
  marginBottom?: boolean
  smallTopMargin?: boolean
  smallBottomMargin?: boolean
}) => {
  const [checked, setChecked] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const AccordionOptionClass: string = marginBottom
    ? 'w-full md:w-4/5 xl:w-2/3 mb-8'
    : 'w-full md:w-4/5 xl:w-2/3'
  const uniqueNumber = uuid()

  useEffect(() => {
    if (checked && contentRef.current) {
      contentRef.current.focus()
    }
  }, [checked])

  const handleCheckboxChange = () => {
    setChecked(!checked)
  }

  return (
    <div className={AccordionOptionClass} ref={contentRef}>
      <input
        type="checkbox"
        id={uniqueNumber}
        className="peer top-0 opacity-0 inset-x-0 z-10 cursor-pointer"
        checked={checked}
        onChange={handleCheckboxChange}
        aria-expanded={checked}
      />
      <label htmlFor={uniqueNumber}>
        <div className="transition ease-in hover:scale-110 duration-300">
          <Option expanded={checked}>{title}</Option>
        </div>
      </label>
      <div className="z-0 transition ease-in duration-300 hidden peer-checked:block peer-checked:visible">
        <div
          className={`bg-white shadow-custom px-8 ${smallTopMargin ? 'pt-6' : 'pt-14'} ${
            smallBottomMargin ? 'pb-1' : 'pb-10'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default AccordionOption
