export type UserData = {
  username: string
  fullName: string
  email: string
  phone: string
  addressLine1: string
  addressLine2?: string
  postcode: string
  city: string
  role: 'USER' | 'ADMIN'
  country?: string
  userId?: string
  rmaCustomerId?: string
}

export type AdminUserData = UserData & {
  phone?: string
  country: string
  role: string
  password?: string
}

export type UserCredentals = {
  username: string
  password: string
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type ResetPasswordData = {
  error?: boolean | null
  password?: string | null
}
