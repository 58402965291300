import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PageTitle, RouteLink } from '../../common'
import {
  dictionaryRepairRequestStatus,
  RepairRequestData,
} from '../../../requests/repair-request/repairRequest.domain'
import { fetchAdminRepairRequestsListStart } from '../../../admin/repair-request/adminRepairRequest.action'
import {
  AdminRepairRequestsFilter,
  RepairRequestSortOrder,
} from '../../../admin/repair-request/adminRepairRequest.domain'
import { MENU_ITEM } from '../../../admin/menu/constants'
import { HorizontalAdminMenu } from '../../../admin/menu/HorizontalAdminMenu'
import usePreviousCompare from '../../../utils/usePreviousCompare'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { Button } from '../../common/button/Button'

export const AdminRepairsListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [listFilter, setListFilter] = useState<AdminRepairRequestsFilter>({ search: '' })
  const [listSortOrder, setListSortOrder] = useState<
    Pick<AdminRepairRequestsFilter, 'sortBy' | 'sortOrder'>
  >({ sortBy: 'requestDatetime', sortOrder: RepairRequestSortOrder.DESC })

  const isListFilterDifferent = usePreviousCompare(listFilter)
  const isListSortOrderDifferent = usePreviousCompare(listSortOrder)

  const adminRepairRequestStore = useAppSelector((state) => state.adminRepairRequest)

  const initialFetchAdminRepairRequestsList = useCallback(() => {
    dispatch(fetchAdminRepairRequestsListStart({ ...listSortOrder }))
  }, [dispatch, listSortOrder])

  const goToRepairRequest = (item: RepairRequestData) => {
    navigate(RouteLink.ADMIN_REQUEST_SERVICE + `/${item.requestId}`)
  }

  useEffect(() => {
    initialFetchAdminRepairRequestsList()
  }, [initialFetchAdminRepairRequestsList])

  const searchAction = () => {
    if (isListFilterDifferent)
      dispatch(fetchAdminRepairRequestsListStart({ ...listFilter, ...listSortOrder }))
  }

  const handleEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' && isListFilterDifferent) {
      searchAction()
    }
  }

  useEffect(() => {
    if (isListSortOrderDifferent) {
      dispatch(fetchAdminRepairRequestsListStart({ ...listFilter, ...listSortOrder }))
    }
  }, [isListSortOrderDifferent, listFilter, listSortOrder, dispatch])

  const activeColumnClass = useCallback(
    (column) => {
      return listSortOrder.sortBy === column ? 'font-weight-bolder' : ''
    },
    [listSortOrder]
  )

  return (
    <div className="px-4 pb-10">
      <HorizontalAdminMenu current={MENU_ITEM.REPAIR_LIST} />
      <div className="mb-10">
        <PageTitle value="Admin mode - Repair requests" />
      </div>
      <div className="bg-white pt-4 pb-6 pl-4 flex items-center">
        <input
          name="searchFilter"
          id="searchFilter"
          type="search"
          className="border border-gray p-2"
          value={listFilter.search}
          placeholder="Search data"
          onKeyDown={handleEnterKeyDown}
          onChange={(e) => setListFilter({ search: e.target.value })}
        />
        <Button className="button button-primary ml-2 text-xs" onClick={() => searchAction()}>
          Search
        </Button>
      </div>
      <div className="grid grid-col-1 md:grid-cols-7 bg-gray border border-l-0 border-r-0 py-2 px-2">
        {[
          ['fullName', 'Kundenname'],
          ['productModel', 'Produktbezeichnung'],
          ['serialNumber', 'Seriennummer'],
          ['requestDatetime', 'Abgabedatum'],
          ['currentStatus', 'Status'],
          ['modificationDatetime', 'Modif. datum'],
        ].map(([name, label]) => (
          <div key="name">
            <span className={`${activeColumnClass(name)}`}>{label}</span>
            <FontAwesomeIcon
              className="pointer mx-1"
              icon={faSortDown}
              onClick={() =>
                setListSortOrder({ sortBy: name, sortOrder: RepairRequestSortOrder.DESC })
              }
            />
            <FontAwesomeIcon
              className="pointer"
              icon={faSortUp}
              onClick={() =>
                setListSortOrder({ sortBy: name, sortOrder: RepairRequestSortOrder.ASC })
              }
            />
          </div>
        ))}
        <div className="col"></div>
      </div>
      {adminRepairRequestStore.list.length ? (
        <div>
          {adminRepairRequestStore.list.map((item, index) => (
            <div
              className={`grid grid-col-1 md:grid-cols-7 py-2 px-2 ${
                index + 1 < adminRepairRequestStore.list.length ? 'border-b' : ''
              } ${item.read ? 'bg-white' : 'bg-neutral-100 font-bold'} hover:bg-slate-100`}
              key={'admin-repair-request-list-key-' + item.requestId}
            >
              <div className="flex items-center">{item.fullName}</div>
              <div className="flex items-center">{item.productModel}</div>
              <div className="flex items-center">{item.serialNumber || '-'}</div>
              <div className="flex items-center">{item.requestDatetime}</div>
              <div className="flex items-center">
                {item.currentStatus
                  ? t(dictionaryRepairRequestStatus.item(item.currentStatus.toString()).value)
                  : 'N/A'}
              </div>
              <div className="flex items-center">
                {item.modificationDatetime}
                <br />
                {item.lastModificationUser?.fullName}
              </div>
              <div className="flex md:justify-end">
                {item.requestId && (
                  <Button className="font-normal" onClick={() => goToRepairRequest(item)}>
                    Edit
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <span>Keine Elemente gefunden</span>
      )}
    </div>
  )
}
