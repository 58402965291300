import { useTranslation } from 'react-i18next'

import facebookIcon from '../../../assets/icons/facebookIcon.svg'
import instagramIcon from '../../../assets/icons/instagramIcon.svg'
import linkedinIcon from '../../../assets/icons/linkedinIcon.svg'
import youtubeIcon from '../../../assets/icons/youtubeIcon.svg'
import './AppFooter.scss'

export const AppFooter = () => {
  const { t } = useTranslation()
  return (
    <div className="container mx-auto">
      <div className="py-16 px-4 grid grid-cols-1 sm:grid-cols-2">
        <div className="flex flex-col font-untitled-sans text-lg text-gray-10 lg:flex-row">
          <div className="lg:mr-2">
            <span>© {new Date().getFullYear()} Burmester. All rights reserved.</span>
          </div>
          <div className="space-x-2">
            <a
              href="https://www.burmester.de/ShoppagesNavigation/Impressum/"
              className="no-underline hover:underline"
            >
              {t('common.imprint')}
            </a>
            <span>·</span>
            <a
              href="https://www.burmester.de/ShoppagesNavigation/Datenschutz/"
              className="no-underline hover:underline"
            >
              {t('common.privacy')}
            </a>
          </div>
        </div>
        <div className="flex justify-start space-x-4 mt-8 sm:justify-end sm:mt-0">
          {[
            ['fb', facebookIcon, 'https://www.facebook.com/Burmesteraudiosysteme/'],
            ['in', instagramIcon, 'https://www.instagram.com/burmesteraudio/'],
            ['yt', youtubeIcon, 'https://www.youtube.com/channel/UCnBxyca47X2CcOEnY_9LaEQ'],
            ['li', linkedinIcon, 'https://www.linkedin.com/company/burmester-audiosysteme-gmbh/'],
          ].map((iconDetails) => {
            return (
              <a
                href={iconDetails[2]}
                key={iconDetails[0]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-8 h-8 p-2 outline outline-1 border-gray-10 rounded-full">
                  <img src={iconDetails[1]} alt="" />
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
