import { put, call, takeLatest } from 'redux-saga/effects'

import {
  createRepairRequestError,
  createRepairRequestInProgress,
  createRepairRequestsDone,
  fetchRepairRequestDetailsDone,
  fetchRepairRequestDetailsError,
  fetchRepairRequestDetailsInProgress,
  fetchRepairRequestsListDone,
  fetchRepairRequestsListError,
  fetchRepairRequestsListInProgress,
  ICreateRepairRequestStartAction,
  IFetchRepairRequestDetailsStartAction,
  REPAIR_REQUEST_CREATE_START,
  REPAIR_REQUEST_FETCH_DETAILS_START,
  REPAIR_REQUEST_FETCH_LIST_START,
} from './repairRequest.action'
import {
  convertRepairRequestFormToData,
  getRepairRequestDetails,
  getRepairRequests,
  postRepairRequests,
} from './repairRequest.service'
import { RepairRequestData } from './repairRequest.domain'

import { authUserLogoutAction } from '../../auth/auth.actions'

// calling get list
export function* watchForFetchRepairRequestsListStart() {
  yield takeLatest(REPAIR_REQUEST_FETCH_LIST_START, callGetRepairRequestsList)
}

function* callGetRepairRequestsList() {
  try {
    yield put(fetchRepairRequestsListInProgress())
    const response: RepairRequestData[] = yield call(getRepairRequests)
    yield put(fetchRepairRequestsListDone(response))
  } catch (error: unknown) {
    yield put(fetchRepairRequestsListError(error as Error))
    yield put(authUserLogoutAction())
  }
}

// calling create
export function* watchForCreateRepairRequestStart() {
  yield takeLatest(REPAIR_REQUEST_CREATE_START, callPostRepairRequest)
}

function* callPostRepairRequest(action: ICreateRepairRequestStartAction) {
  try {
    yield put(createRepairRequestInProgress())

    const data = convertRepairRequestFormToData(action.repairRequestForm)
    const invoiceFile = action.repairRequestForm.invoice
    const response: RepairRequestData = yield call(postRepairRequests, data, invoiceFile)
    yield put(createRepairRequestsDone(response))
  } catch (error: unknown) {
    yield put(createRepairRequestError(error as Error))
    yield put(authUserLogoutAction())
  }
}

// calling get details
export function* watchForFetchRepairRequestDetailsStart() {
  yield takeLatest(REPAIR_REQUEST_FETCH_DETAILS_START, callGetRepairRequestDetails)
}

function* callGetRepairRequestDetails(action: IFetchRepairRequestDetailsStartAction) {
  try {
    yield put(fetchRepairRequestDetailsInProgress())
    const response: RepairRequestData = yield call(getRepairRequestDetails, action.repairRequestId)
    yield put(fetchRepairRequestDetailsDone(response))
  } catch (error: unknown) {
    yield put(fetchRepairRequestDetailsError(error as Error))
    yield put(authUserLogoutAction())
  }
}
