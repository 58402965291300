import React, { KeyboardEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GetAdminGuaranteesList } from '../../../admin/guarantees/adminGuarantees.action'
import { HorizontalAdminMenu } from '../../../admin/menu/HorizontalAdminMenu'
import { MENU_ITEM } from '../../../admin/menu/constants'
import { PageTitle } from '../../common'
import GuaranteesTable from '../../common/table/GuaranteesTable'
import './AdminGuaranteesList.scss'
import usePreviousCompare from '../../../utils/usePreviousCompare'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { Button } from '../../common/button/Button'
import {
  deleteGuarantee,
  downloadGuaranteeReport,
} from '../../../admin/guarantees/adminGuarantees.service'

const AdminGuaranteesList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchValue, setSearchValue] = useState<string>('')
  const guaranteesList = useAppSelector((state) => state?.adminGuaranteesList?.guaranteesList)
  const [downloadErrorMessage, setDownloadErrorMessage] = useState<string>('')
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>('')

  const prevSearchValueDiff = usePreviousCompare(searchValue)

  useEffect(() => {
    dispatch(GetAdminGuaranteesList(''))
  }, [dispatch])

  const onClickSearch = () => {
    if (prevSearchValueDiff) {
      dispatch(GetAdminGuaranteesList(searchValue))
    }
  }

  const handleEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' && prevSearchValueDiff) {
      dispatch(GetAdminGuaranteesList(searchValue))
    }
  }

  const downloadReport = async () => {
    try {
      setDownloadErrorMessage('')
      setDeleteErrorMessage('')
      await downloadGuaranteeReport()
    } catch (e) {
      setDownloadErrorMessage(t('common.errorDownloadFile'))
    }
  }

  const deleteGuaranteeItem = async (guaranteeNumber: string) => {
    try {
      setDeleteErrorMessage('')
      setDownloadErrorMessage('')
      await deleteGuarantee(guaranteeNumber)
      dispatch(GetAdminGuaranteesList(''))
    } catch (e) {
      setDeleteErrorMessage(t('common.errorDelete'))
    }
  }

  return (
    <div className="admin-guarantees-container">
      <HorizontalAdminMenu current={MENU_ITEM.GUARANTEES_LIST} />
      <div className="container">
        <PageTitle value="Admin mode - Guarantees list" />
      </div>
      <div className="container">
        <div className="row small border-bottom border-top px-2 py-1 mt-3 mb-3 bg-light">
          <input
            type="search"
            className="border border-gray p-2"
            placeholder="Search data"
            onKeyDown={handleEnterKeyDown}
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
          <Button className="button button-primary ml-2 px-2 py-1" onClick={onClickSearch}>
            Search
          </Button>
          <Button className="button button-primary ml-4 px-2 py-1" onClick={downloadReport}>
            Download CSV
          </Button>
        </div>
        {downloadErrorMessage ? (
          <div className="text-red-500 text-lg py-2">{downloadErrorMessage}</div>
        ) : (
          <></>
        )}
      </div>
      <div className="container">
        <div className="row">
          {guaranteesList && (
            <GuaranteesTable
              key={guaranteesList}
              guaranteesList={guaranteesList}
              deleteGuaranteeItem={deleteGuaranteeItem}
              deleteErrorMessage={deleteErrorMessage}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminGuaranteesList
