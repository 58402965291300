import { AnyAction, Reducer } from 'redux'

import { USER_FETCH_DONE, USER_FETCH_ERROR, USER_FETCH_IN_PROGRESS } from './user.actions'
import { UserData } from './user.domain'

export type UserStateType = {
  userData: UserData | null
  inProgress: boolean
  error: Error | null
}

const initialState: UserStateType = {
  userData: null,
  inProgress: false,
  error: null,
}

const reducer: Reducer<UserStateType, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_IN_PROGRESS:
      return {
        ...initialState,
        inProgress: true,
      }

    case USER_FETCH_DONE:
      return {
        ...state,
        userData: action.userData,
        inProgress: false,
      }

    case USER_FETCH_ERROR:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      }

    default:
      return state
  }
}

export default reducer
