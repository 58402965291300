import React from 'react'

import './Content.scss'

const Content = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-white-light px-4">
      <div className="container mx-auto">{children}</div>
    </div>
  )
}

export default Content
