import React from 'react'
import './RepairRequestPage.scss'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { RepairStatusFileComponent } from './RepairStatusFileComponent'

import {
  dictionaryRepairRequestStatus,
  RepairRequestData,
  RepairRequestStatus,
  RepairRequestStatusData,
} from '../../requests/repair-request/repairRequest.domain'

type Props = {
  repairRequest: RepairRequestData
  displayStatus: RepairRequestStatus
  additionalStyle?: string
  hideFile?: boolean
  adminMode?: boolean
  fileMetadata?: {
    fileIcon: IconDefinition
    fileTitle: string
    fileSubtitle: string
    fileButtonTitle: string
    fileStatus?: string
  }
}

export const RepairStatusComponent = (props: Props) => {
  const { t } = useTranslation()

  const title = t(dictionaryRepairRequestStatus.item(props.displayStatus).value)
  const subtitle = t(dictionaryRepairRequestStatus.item(props.displayStatus).subvalue as string)

  const getStatus = (status: RepairRequestStatus): RepairRequestStatusData | undefined => {
    return props.repairRequest?.statuses
      ? props.repairRequest.statuses.find((s) => s.type === status)
      : undefined
  }

  const displayStyle = (status: RepairRequestStatus) => {
    if (getStatus(status)) {
      if (props.repairRequest?.currentStatus !== status) {
        return 'status-done'
      } else if (props.repairRequest?.currentStatus === status) {
        return 'status-active'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  const containedStatus = getStatus(props.displayStatus)

  const fileMetadata = props.fileMetadata
    ? props.fileMetadata
    : {
        fileIcon: faCopy,
        fileTitle: t('dictionaryRepairRequestStatus.default.file.title'),
        fileSubtitle: '',
        fileButtonTitle: t('dictionaryRepairRequestStatus.default.file.button'),
      }

  return (
    <div
      className={
        'repair-request-status-wrap fs-3 fw-600 ' +
        props.additionalStyle +
        ' ' +
        displayStyle(props.displayStatus)
      }
    >
      <div className="title">{title}</div>
      {subtitle ? <div className="subtitle">{subtitle}</div> : null}
      {!props.hideFile && containedStatus?.files
        ? containedStatus.files.map(
            (file, idx) =>
              containedStatus.id &&
              props.repairRequest.requestId && (
                <RepairStatusFileComponent
                  key={file}
                  index={idx}
                  icon={fileMetadata.fileIcon}
                  title={fileMetadata.fileTitle}
                  subtitle={fileMetadata.fileSubtitle}
                  buttonTitle={fileMetadata.fileButtonTitle}
                  requestId={props.repairRequest.requestId}
                  requestStatusId={containedStatus.id}
                  fileName={file}
                  adminMode={props.adminMode}
                />
              )
          )
        : null}
      {containedStatus && (containedStatus.additionalInfo || containedStatus.additionalInfoLink) ? (
        <div className="fs-3 fw-600 pb-5">
          <span>{containedStatus.additionalInfo}</span>
          {containedStatus.additionalInfoLink ? (
            <a
              href={containedStatus.additionalInfoLink}
              target="_blank"
              rel="noreferrer"
              className="button button-primary ml-4"
            >
              {props.displayStatus === RepairRequestStatus.PARCEL_SENT_BACK
                ? t('repairRequestStatus.sending')
                : t('repairRequestStatus.moreInformation')}
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
