import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'

import './index.scss'
import i18n from './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { configureStore, registerSagas } from './configureStore'

const store = configureStore()
registerSagas()

//**** uncomment the line below to use fake backend
// configureFakeBackend()

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
