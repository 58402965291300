import { headers } from '../../../utils/apiHeaders'
import handleResponse from '../../../utils/handleApiResponse'
import { URL_ADMIN_API_USERS } from '../adminUserManagement.service'

export const fetchUserList = () => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(URL_ADMIN_API_USERS, requestOptions)
    .then(handleResponse)
    .then((users) => users)
}
