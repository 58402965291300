import React from 'react'

import { MENU_ITEM } from './constants'

import { RouteLink } from '../../views/common'
import { HorizontalMenuItem } from '../../views/common/horizontal-menu/HorizontalMenuItem'

type Props = {
  current: MENU_ITEM
}
export const HorizontalAdminMenu = (props: Props) => {
  return (
    <div className="pt-6">
      <div className="horizontal-menu">
        <HorizontalMenuItem
          isActive={props.current === MENU_ITEM.USERS}
          title={'Users'}
          link={RouteLink.ADMIN_USERS}
        />
        <HorizontalMenuItem
          isActive={props.current === MENU_ITEM.REPAIR_LIST}
          title={'Reparatur Liste'}
          link={RouteLink.ADMIN_REQUEST_SERVICE}
        />
        <HorizontalMenuItem
          isActive={props.current === MENU_ITEM.GUARANTEES_LIST}
          title={'Guarantees'}
          link={RouteLink.ADMIN_GUARANTEE_LIST}
        />
      </div>
    </div>
  )
}
