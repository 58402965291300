import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../common/button/Button'
import downloadIcon from '../../assets/icons/downloadIcon.svg'

interface ITemplate {
  name: string
  translationName: string
  header?: boolean
}
export const TemplateItem = ({ name, translationName, header }: ITemplate) => {
  const { t, i18n } = useTranslation()
  const handleDownloadFile = (name: string) => {
    const langCode = i18n.language.toUpperCase()
    let pdfUrl
    switch (name) {
      case 'BC150':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/Bespoke_Template_BC150_${langCode}.pdf`
        break
      case 'B18':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/B18+Loudspeaker/Bespoke_Template_B18_${langCode}.pdf`
        break
      case 'B28':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/B28+Loudspeaker/Bespoke_Template_B28_${langCode}.pdf`
        break
      case 'B38':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/B38+Loudspeaker/Bespoke_Template_B38_${langCode}.pdf`
        break
      case 'BC350':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/BC350+Loudspeaker/Bespoke_Template_BC350_${langCode}.pdf`
        break
      case '232':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/Bespoke_Template_232_${langCode}.pdf`
        break
      case '218':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/Bespoke_Template_218_${langCode}.pdf`
        break
      case '111':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/111+Musiccenter/Bespoke_Template_111_${langCode}.pdf`
        break
      case '159':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/159+Power+Amplifier/Bespoke_Template_159_${langCode}.pdf`
        break
      case '216':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/216+Power+Amplifier/Bespoke_Template_216_${langCode}.pdf`
        break
      case '909':
        pdfUrl = `https://burmester-serviceportal-static-files.s3.eu-central-1.amazonaws.com/bespoke/909+Power+Amplifier/Bespoke_Template_909_${langCode}.pdf`
        break
      default:
        break
    }
    window.open(pdfUrl, '_blank')
  }
  return (
    <div className="w-[46rem] mb-12 border-b border-1 border-solid border-gray-10">
      <div className="flex justify-between mb-8">
        {header ? (
          <>
            <div className="flex flex-col justify-between pb-4">
              <span className="text-gray">{t('bespoke.bespokeProductTemplate')}</span>
              <span className="text-gray-10">{t(`${translationName}`)}</span>
            </div>
            <div className="flex flex-col justify-content gap-10">
              <span className="text-gray">{t('bespoke.pdfDownload')}</span>
              <div className="flex justify-center">
                <Button
                  className="border-none noMargin"
                  onClick={() => handleDownloadFile(`${name}`)}
                >
                  <img src={downloadIcon} alt="" width={32} />
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col justify-end pb-4">
              <span className="text-gray-10">{t(`${translationName}`)}</span>
            </div>
            <div className="flex flex-col gap-10">
              <div className="flex justify-center mx-5 pr-0.5">
                <Button
                  className="border-none noMargin"
                  onClick={() => handleDownloadFile(`${name}`)}
                >
                  <img src={downloadIcon} alt="" width={32} />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
