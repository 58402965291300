export const SuccessBox = ({
  text,
  onClick,
  title,
}: {
  text: string | null
  onClick: () => void
  title?: string | null
}) => {
  return (
    <div className="flex justify-center pb-3">
      <div
        className="bg-green-500 border border-green-400 text-white font-bold px-4 py-3 rounded relative w-full mx-2 lg:mx-0 md:w-11/12 lg:w-10/12 xl:w-3/4"
        role="contentinfo"
      >
        <div className="flex items-center">
          {title && <strong>{title}</strong>}
          <span className="block sm:inline ml-2 text-base">{text}</span>
        </div>
        <span className="absolute top-0 bottom-0 right-0 flex items-center pr-3">
          <svg
            className="fill-current h-6 w-6 text-white"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            onClick={onClick}
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    </div>
  )
}
