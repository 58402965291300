import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ADMIN_RESET_USER_PASSWORD_START,
  IAdminUserResetPasswordStartAction,
  resetUserPasswordActionInProgress,
  resetUserPasswordActionDone,
  resetUserPasswordActionError,
} from './resetPassword.actions'
import { resetPasswordAdminUser } from './resetPassword.services'

export function* watchForResetPasswordAdminUserStart() {
  yield takeLatest(ADMIN_RESET_USER_PASSWORD_START, callResetPasswordAdminUser)
}

function* callResetPasswordAdminUser(action: IAdminUserResetPasswordStartAction) {
  try {
    yield put(resetUserPasswordActionInProgress())
    const response: { password: string } = yield call(resetPasswordAdminUser, action.username)
    yield put(resetUserPasswordActionDone(response.password))
  } catch (error: unknown) {
    yield put(resetUserPasswordActionError(error as Error))
  }
}
