import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../utils/hooks'
import NewPageTitle from '../common/new-page-title/NewPageTitle'
import { RouteLink } from '../common/routes'
import suImage from '../../assets/images/menu/Image_Softwareupdate.png'
import rmaImage from '../../assets/images/menu/Image_RMA_Service.png'
import mediaImage from '../../assets/images/menu/Image_mediadatabase.png'
import appImage from '../../assets/images/menu/Image_Apps.png'
import contactImage from '../../assets/images/menu/Image_Kontakt.png'
import productionImage from '../../assets/images/menu/Image_Production.png'
import experienceImage from '../../assets/images/menu/Image_Experience.png'
import bespokeImage from '../../assets/images/menu/Image_Bespoke.png'
import priceListImage from '../../assets/images/menu/Image_Pricelist.jpg'
import manualImage from '../../assets/images/menu/Image_Manuals.webp'

interface LinkType {
  name: string
  label: string
  img: string
  externalLink: boolean
  url: string
}
export const Menu = () => {
  const { t, i18n } = useTranslation()
  const country = useAppSelector((state) => state.user?.userData?.country ?? '')
  const language = i18n.language

  const links: LinkType[] = useMemo(
    () => [
      {
        name: 'softwareUpdates',
        label: t('menu.options.softwareUpdates'),
        img: suImage,
        externalLink: true,
        url: 'http://support.burmester.de/index_de.php',
      },
      {
        name: 'rmaService',
        label: t('menu.options.rmaService'),
        img: rmaImage,
        externalLink: false,
        url: RouteLink.REQUEST_SERVICE,
      },
      {
        name: 'mediaDatabase',
        label: t('menu.options.mediaDatabase'),
        img: mediaImage,
        externalLink: true,
        url: 'https://media.burmester.de/',
      },
      {
        name: 'exclusiveMaterial',
        label: t('menu.options.exclusiveMaterial'),
        img: productionImage,
        externalLink: true,
        url:
          localStorage.getItem('i18nextLng') === 'en'
            ? 'https://media.burmester.de/en/login/'
            : 'https://media.burmester.de/anmeldung/',
      },
      {
        name: 'bespoke',
        label: t('menu.options.bespoke'),
        img: bespokeImage,
        externalLink: false,
        url: RouteLink.BESPOKE,
      },
      {
        name: 'app',
        label: t('menu.options.app'),
        img: appImage,
        externalLink: false,
        url: RouteLink.APP,
      },
      {
        name: 'experience',
        label: t('menu.options.experience'),
        img: experienceImage,
        externalLink: true,
        url:
          localStorage.getItem('i18nextLng') === 'en'
            ? 'https://www.burmester.de/en/the-burmester-experience/'
            : 'https://www.burmester.de/die-burmester-experience/',
      },
      {
        name: 'priceList',
        label: t('menu.options.priceList'),
        img: priceListImage,
        externalLink: false,
        url: RouteLink.PRICE_LIST,
      },
      {
        name: 'manuals',
        label: t('menu.options.manuals'),
        img: manualImage,
        externalLink: true,
        url:
          localStorage.getItem('i18nextLng') === 'en'
            ? 'https://manuals.burmester.de/en/'
            : 'https://manuals.burmester.de/',
      },
      {
        name: 'contact',
        label: t('menu.options.contact'),
        img: contactImage,
        externalLink: false,
        url: RouteLink.CONTACT,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, language]
  )

  const [linksData, setLinksData] = useState<LinkType[]>([])

  useEffect(() => {
    if (
      country !== 'Germany' &&
      country !== 'Deutschland' &&
      country !== 'Austria' &&
      country !== 'Österreich'
    ) {
      const linksSet = links.filter((item) => item.name !== 'rmaService')
      setLinksData(linksSet)
    } else {
      setLinksData(links)
    }
  }, [country, links])

  const CardLink = ({
    children,
    externalLink,
    url,
  }: {
    children: React.ReactNode
    externalLink: boolean
    url: string
  }) => {
    return externalLink ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ) : (
      <Link to={url}>{children}</Link>
    )
  }

  return (
    <div className="container">
      <div className="pb-16">
        <NewPageTitle topText={t('menu.topText')} title={t('menu.title')}>
          {t('menu.pageDescription')}
        </NewPageTitle>
      </div>
      <div className="grid grid-cols-12 pb-32">
        <div className="col-start-3 col-span-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-16">
            {linksData.map((item) => (
              <CardLink key={item.name} externalLink={item.externalLink} url={item.url}>
                <div className="bg-white w-full min-h-80 max-h-80 transition ease-in hover:scale-110 duration-300">
                  <div className="flex flex-col">
                    <div className="basic-1/3 p-6">
                      <div className="flex">
                        <div className="w-2/3">
                          <span className="font-untitled-sans text-lg text-gray-15 uppercase">
                            {item.label}
                          </span>
                        </div>
                        <div className="w-1/3 text-end">
                          <span className="text-6xl font-normal text-gray">+</span>
                        </div>
                      </div>
                    </div>
                    <div className="basic-2/3 bg-neutral-400">
                      <img src={item.img} alt={item.label} className="mix-blend-overlay" />
                    </div>
                  </div>
                </div>
              </CardLink>
            ))}
          </div>
        </div>
      </div>
      {/*<div className="contact-form">*/}
      {/*  <ContactForm />*/}
      {/*</div>*/}
    </div>
  )
}
