import { Action } from 'redux'

import { UserData } from './user.domain'

export const USER_FETCH_START = 'USER/FETCH_START'
export const USER_FETCH_IN_PROGRESS = 'USER/FETCH_IN_PROGRESS'
export const USER_FETCH_DONE = 'USER/FETCH_DONE'
export const USER_FETCH_ERROR = 'USER/FETCH_ERROR'

// export const UNAUTHORIZED_ERROR = 'USER/UNAUTHORIZED_ERROR'
// export const UNEXPECTED_ERROR   = 'USER/UNEXPECTED_ERROR'

export interface IFetchUserStartAction extends Action {
  type: typeof USER_FETCH_START
}
export interface IFetchUserInProgressAction extends Action {
  type: typeof USER_FETCH_IN_PROGRESS
}
export interface IFetchUserDoneAction extends Action {
  type: typeof USER_FETCH_DONE
  userData: UserData | null
}

export interface IFetchUserErrorAction extends Action {
  type: typeof USER_FETCH_ERROR
  error: Error
}

export const fetchUserStartAction = (): IFetchUserStartAction => {
  return {
    type: USER_FETCH_START,
  }
}

export const fetchUserInProgressAction = (): IFetchUserInProgressAction => {
  return {
    type: USER_FETCH_IN_PROGRESS,
  }
}

export const fetchUserDoneAction = (userData: UserData | null): IFetchUserDoneAction => {
  return {
    type: USER_FETCH_DONE,
    userData,
  }
}

export const fetchUserErrorAction = (error: Error): IFetchUserErrorAction => {
  return {
    type: USER_FETCH_ERROR,
    error,
  }
}
