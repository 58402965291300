import { TableStyles } from 'react-data-table-component'

type BorderStyle =
  | 'solid'
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'
interface ICustomStylesForUserTable extends TableStyles {
  rows: {
    style: {
      height: string
      borderBottomWidth: string
      borderBottomStyle: BorderStyle
      borderBottomColor: string
      padding: string
      backgroundColor: string
      '&:not(:last-of-type)'?: {
        borderBottomStyle: 'solid'
        borderBottomWidth: '1px'
        borderBottomColor: 'black'
      }
      '&:hover'?: {
        backgroundColor: string
      }
    }
  }
  headCells: {
    style: {
      height: string
      lineHeight: string
      fontWeight: string
      borderTop: string
      borderBottom: string
      backgroundColor: string
    }
  }
  headRow: {
    style: {
      height: string
      backgroundColor: string
      borderBottomWidth: string
    }
  }
  header: {
    style: {
      height: string
      backgroundColor: string
      marginBottom: string
      justifyContent: string
    }
  }
  subHeader: {
    style: {
      justifyContent: string
      padding: string
    }
  }
}

export const customStylesForUserTable: ICustomStylesForUserTable = {
  rows: {
    style: {
      height: '70px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'black',
      padding: '0.5rem',
      backgroundColor: '#F7F7F7',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'black',
      },
      '&:hover': {
        backgroundColor: 'rgb(241 245 249)',
      },
    },
  },
  headCells: {
    style: {
      height: '36px',
      lineHeight: '18px',
      fontWeight: '300',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      backgroundColor: 'rgb(199 186 167)',
    },
  },
  headRow: {
    style: {
      height: '36px',
      backgroundColor: '#F7F7F7',
      borderBottomWidth: '0',
    },
  },
  header: {
    style: {
      height: '36px',
      backgroundColor: 'rgb(199 186 167)',
      marginBottom: '16px',
      justifyContent: 'flex-start',
    },
  },
  subHeader: {
    style: {
      justifyContent: 'flex-start',
      padding: '0 1rem',
    },
  },
}
