import { AnyAction, Reducer } from 'redux'

import { ADMIN_SET_GUARANTEES_LIST } from './adminGuarantees.action'
import { GuaranteeType } from './adminGuarantees.domain'

type AdminGuaranteesStateType = {
  guaranteesList: GuaranteeType[]
}

const initialState: AdminGuaranteesStateType = {
  guaranteesList: [],
}

const reducer: Reducer<AdminGuaranteesStateType, AnyAction> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case ADMIN_SET_GUARANTEES_LIST:
      return {
        ...state,
        guaranteesList: action.requestData,
      }
    default:
      return state
  }
}

export default reducer
