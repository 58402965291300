import React from 'react'
import { faCopy, faReceipt } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { RepairStatusComponent } from './RepairStatusComponent'
import './RepairRequestStatusLayout.scss'

import {
  RepairRequestData,
  RepairRequestStatus,
} from '../../requests/repair-request/repairRequest.domain'
import { TextContentLoader } from '../common/loaders'

type Props = {
  repairRequest: RepairRequestData | null | undefined
  hideInvoiceFile: boolean
  adminMode?: boolean
}

export const RepairRequestStatusTree = (props: Props) => {
  const { t } = useTranslation()
  return props.repairRequest ? (
    <div className="repair-requests">
      <RepairStatusComponent
        repairRequest={props.repairRequest}
        displayStatus={RepairRequestStatus.DATA_SENT}
        hideFile={props.hideInvoiceFile}
        adminMode={props.adminMode}
        fileMetadata={{
          fileIcon: faReceipt,
          fileTitle: t('dictionaryRepairRequestStatus.dataSent.file.title'),
          fileSubtitle: '',
          fileButtonTitle: t('dictionaryRepairRequestStatus.dataSent.file.button'),
        }}
      />
      <RepairStatusComponent
        repairRequest={props.repairRequest}
        displayStatus={RepairRequestStatus.REQUEST_TAKEN}
        adminMode={props.adminMode}
        fileMetadata={{
          fileIcon: faReceipt,
          fileTitle: t('dictionaryRepairRequestStatus.requestTaken.file.title'),
          fileSubtitle: t('dictionaryRepairRequestStatus.requestTaken.file.subtitle'),
          fileButtonTitle: t('dictionaryRepairRequestStatus.requestTaken.file.button'),
        }}
      />

      <RepairStatusComponent
        repairRequest={props.repairRequest}
        displayStatus={RepairRequestStatus.PARCEL_RECEIVED}
        adminMode={props.adminMode}
      />

      <RepairStatusComponent
        repairRequest={props.repairRequest}
        displayStatus={RepairRequestStatus.COST_ESTIMATION}
        adminMode={props.adminMode}
        fileMetadata={{
          fileIcon: faCopy,
          fileTitle: `${t('dictionaryRepairRequestStatus.costEstimation.file.title')} ${
            props.repairRequest?.requestId
          }`,
          fileSubtitle: t('dictionaryRepairRequestStatus.costEstimation.file.subtitle'),
          fileButtonTitle: t('dictionaryRepairRequestStatus.costEstimation.file.button'),
        }}
      />

      <RepairStatusComponent
        repairRequest={props.repairRequest}
        displayStatus={RepairRequestStatus.REPAIR_FINISHED}
        adminMode={props.adminMode}
      />

      <RepairStatusComponent
        repairRequest={props.repairRequest}
        displayStatus={RepairRequestStatus.RECEIPT}
        adminMode={props.adminMode}
        fileMetadata={{
          fileIcon: faCopy,
          fileTitle: `${t('dictionaryRepairRequestStatus.receipt.file.title')} ${
            props.repairRequest?.requestId
          }`,
          fileSubtitle: t('dictionaryRepairRequestStatus.receipt.file.subtitle'),
          fileButtonTitle: t('dictionaryRepairRequestStatus.receipt.file.button'),
          fileStatus: t('dictionaryRepairRequestStatus.receipt.file.status') as string,
        }}
      />

      <RepairStatusComponent
        repairRequest={props.repairRequest}
        additionalStyle={'last'}
        displayStatus={RepairRequestStatus.PARCEL_SENT_BACK}
        adminMode={props.adminMode}
      />
    </div>
  ) : (
    <TextContentLoader />
  )
}
