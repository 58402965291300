import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AdminUserListItem } from './AdminUserListItem'

import { HorizontalAdminMenu } from '../../../admin/menu/HorizontalAdminMenu'
import { MENU_ITEM } from '../../../admin/menu/constants'
import { PageTitle, RouteLink } from '../../../views/common'
import { fetchUserListActionStart } from '../../../admin/user-management/user-list/adminUserList.actions'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { SuccessBox } from '../../common/successBox/SuccessBox'
import { ErrorBox } from '../../common/errorBox/ErrorBox'
import { Button } from '../../common/button/Button'

export const AdminUserListPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const adminUserManagementState = useAppSelector((state) => state.adminUserManagement)
  const newPassword = useAppSelector((state) => state.adminUserManagement.newPassword)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [usernameForResetPassword, setUsernameForResetPassword] = useState<string>('')

  useEffect(() => {
    dispatch(fetchUserListActionStart())
  }, [dispatch])

  return (
    <div className="px-4 pb-10">
      <HorizontalAdminMenu current={MENU_ITEM.USERS} />
      <div>
        <div className="flex justify-between pb-10">
          <PageTitle value="Admin mode - Users list" />
          {adminUserManagementState.inProgress && adminUserManagementState.users.length !== 0 && (
            <div className="ml-3 spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          <Link to={RouteLink.ADMIN_USER_CREATE}>
            <Button>
              <div className="flex">
                <div className="font-normal tracking-wider text-gray-15">
                  {t('common.createUser')}
                </div>
              </div>
            </Button>
          </Link>
        </div>
        {showSuccessMessage && newPassword.password ? (
          <SuccessBox
            text={` ${t('common.newPasswordCreated', {
              userName: usernameForResetPassword,
              password: newPassword.password,
            })}`}
            onClick={() => setShowSuccessMessage(false)}
          />
        ) : (
          <></>
        )}
        {showSuccessMessage && newPassword.error ? (
          <ErrorBox
            text={t('common.errorResetPassword')}
            onClick={() => setShowSuccessMessage(false)}
          />
        ) : (
          <></>
        )}
        <div className="container">
          {adminUserManagementState.inProgress && adminUserManagementState.users.length === 0 && (
            <div className="container d-flex align-items-center justify-content-center">
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <AdminUserListItem
            setShowSuccessMessage={setShowSuccessMessage}
            setUsernameForResetPassword={setUsernameForResetPassword}
            // @ts-ignore
            tableData={adminUserManagementState.users}
          />
        </div>
      </div>
    </div>
  )
}
