import { Reducer } from 'redux'

import {
  CUSTOMER_RMA_FETCH_START,
  CUSTOMER_RMA_FETCH_IN_PROGRESS,
  CUSTOMER_RMA_FETCH_DONE,
  CUSTOMER_RMA_FETCH_ERROR,
  ICustomerFetchStartAction,
  ICustomerFetchInProgressAction,
  ICustomerFetchDoneAction,
  ICustomerFetchErrorAction,
} from './customer.action'
import { CustomerRmaType } from './customer.domain'

type CustomerActionTypes =
  | ICustomerFetchStartAction
  | ICustomerFetchInProgressAction
  | ICustomerFetchDoneAction
  | ICustomerFetchErrorAction

type CustomerRequestStateType = {
  rmaData: CustomerRmaType[]
  inProgress: boolean
  error?: Error | null
}

const initialState: CustomerRequestStateType = {
  rmaData: [],
  inProgress: false,
  error: null,
}
const reducer: Reducer<CustomerRequestStateType, CustomerActionTypes> = (
  state: CustomerRequestStateType = initialState,
  action: CustomerActionTypes
) => {
  switch (action.type) {
    case CUSTOMER_RMA_FETCH_START:
      return {
        ...state,
      }
    case CUSTOMER_RMA_FETCH_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case CUSTOMER_RMA_FETCH_DONE:
      return {
        ...state,
        rmaData: action.rmaData,
        inProgress: false,
      }

    case CUSTOMER_RMA_FETCH_ERROR:
      return {
        ...state,
        rmaData: [],
        error: action.error,
        inProgress: false,
      }

    default:
      return state
  }
}
export default reducer
