import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './RepairRequestPage.scss'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { useTranslation } from 'react-i18next'

import { downloadFile } from '../../requests/repair-request/repairRequest.service'
import { downloadAdminFile } from '../../admin/repair-request/adminRepairRequest.service'

type Props = {
  index: number
  icon: IconDefinition
  title: string
  subtitle: string
  buttonTitle: string
  fileName: string
  requestId: string
  requestStatusId: number
  status?: string
  adminMode?: boolean
}

export const RepairStatusFileComponent = (props: Props) => {
  const { t } = useTranslation()

  const fileNameFormatted = () => props.fileName.replace(/^.*[\\/]/, '')

  const downloadDocument = () => {
    if (props.adminMode) {
      downloadAdminFile(props.requestId, props.requestStatusId, fileNameFormatted(), props.index)
    } else {
      downloadFile(props.requestId, props.requestStatusId, fileNameFormatted(), props.index)
    }
  }

  return (
    <div className="file-wrap">
      <div className="row">
        <div className="col-md-auto">
          <div className="icon-wrap">
            <span className="icon">
              <FontAwesomeIcon icon={props.icon} />
            </span>
          </div>
        </div>
        <div className="col">
          {props.status ? (
            <div className="fw-400 mb-2">
              <span className="fs-3 text-uppercase">{t('repairRequestStatus.status')}: </span>{' '}
              <span className="fs-4 ml-1">{props.status}</span>
            </div>
          ) : null}
          <div className="file-name fs-3 fw-600 mb-2">{props.title}</div>
          <div className="fs-4 fw-300 mb-4">{props.subtitle}</div>
          <div>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault()
                downloadDocument()
              }}
              className="button button-primary"
            >
              {props.buttonTitle}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
