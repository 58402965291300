import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RepairRequestStatusLayout } from './RepairRequestStatusLayout'

import { fetchRepairRequestDetailsStart } from '../../requests/repair-request/repairRequest.action'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import NewPageTitle from '../common/new-page-title/NewPageTitle'

export const RepairRequestStatusPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const repairRequestStore = useAppSelector((state) => state.repairRequest)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (id) {
      dispatch(fetchRepairRequestDetailsStart(id))
    }
  }, [id, dispatch])

  return (
    <>
      {repairRequestStore.details ? (
        <>
          <NewPageTitle
            topText={t('repairRequestStatus.topText')}
            title={t('repairRequestStatus.title')}
            backButton
          />
          <div className="flex justify-center">
            <div className="w-1/2 bg-white py-6 px-4 mb-20">
              <RepairRequestStatusLayout
                repairRequest={repairRequestStore.details}
                pageTitle={t('repairRequestStatus.repairTitle')}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
