export const androidAppStore = 'Android App Store'
export const iosAppleStore = 'IOS Apple Store'
export const burmesterMcIosLink = 'https://apps.apple.com/us/app/burmestermc/id499609020'
export const burmesterComposeIosLink =
  'https://apps.apple.com/de/app/burmester-compose/id1631150799'
export const burmesterComposeAndroidLink =
  'https://play.google.com/store/apps/details?id=com.Burmester.Compose&pli=1'
export const soundcheckAppMercedesIosLink =
  'https://apps.apple.com/de/app/burmester-soundcheck-daimler/id1275058416'
export const soundcheckAppMercedesAndroidLink =
  'https://play.google.com/store/apps/details?id=de.burmester.android&pli=1'
export const soundcheckAppPorscheIosLink =
  'https://apps.apple.com/us/app/sound-porsche/id1171368228'
export const soundcheckAppPorscheAndroidLink =
  'https://play.google.com/store/apps/details?id=de.burmester.porsche&pli=1'
