import { Link } from 'react-router-dom'

import { Option } from './option/Option'

const LinkOption = ({
  title,
  url,
}: {
  title: string
  url: string
  // longText?: boolean
}) => {
  return (
    <div className="w-full md:w-4/5 xl:w-2/3 z-10 transition ease-in hover:scale-110 duration-300 mb-8">
      <Link to={url}>
        <Option>{title}</Option>
      </Link>
    </div>
  )
}

export default LinkOption
