import React from 'react'
import { useTranslation } from 'react-i18next'

import { TemplateItem } from './TemplateItem'

import NewPageTitle from '../common/new-page-title/NewPageTitle'
import { Button } from '../common/button/Button'
import christianLangnerImg from '../../assets/images/contacts/InnerSales_Christian_Langner.jpg'

export const Bespoke = () => {
  const emailAddress = 'bespoke@burmester.de'
  const contactPerson = 'Christian Langner'
  const phoneNumber = '+49 30 787 968-15'
  const contactPersonEmail = 'Christian.Langner@burmester.de'
  const { t, i18n } = useTranslation()
  return (
    <div className="pt-8 pb-8 lg:pb-28">
      <NewPageTitle topText={t('bespoke.topText')} title={t('bespoke.title')} backButton>
        {t('bespoke.pageDescriptionPart1')}
        <div className="mt-4">
          <p>{t('bespoke.pageDescriptionPart2')}</p>
        </div>
      </NewPageTitle>
      <div className="flex flex-col items-center justify-center">
        <div>
          <a
            href={
              i18n.language.toUpperCase() === 'EN'
                ? 'https://www.burmester.de/en/homeaudio/bespoke/'
                : 'https://www.burmester.de/home-audio/bespoke/'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              <div className="flex">
                <div className="font-normal tracking-wider text-gray-15">
                  {t('bespoke.LinkText')}
                </div>
              </div>
            </Button>
          </a>
        </div>
        <div className="flex justify-center mt-8 mb-8">
          <p className="text-lg w-[46rem] text-gray-10 text-center">
            <span className="font-untitled-sans">{t('bespoke.pageDescriptionPart3')}</span>
            <span className="font-bold">{` ${emailAddress} `}</span>
          </p>
        </div>
        <div className="flex justify-center mb-32">
          <p className="font-bold text-lg w-[46rem] text-gray-10 text-center">
            {t('bespoke.pageDescriptionPart4')}
          </p>
        </div>
        <TemplateItem
          name={'BC150'}
          translationName={'bespoke.loudspeakerModelBC150'}
          header={true}
        />
        <TemplateItem name={'BC350'} translationName={'bespoke.loudspeakerModelBC350'} />
        <TemplateItem name={'B18'} translationName={'bespoke.loudspeakerModelB18'} />
        <TemplateItem name={'B28'} translationName={'bespoke.loudspeakerModelB28'} />
        <TemplateItem name={'B38'} translationName={'bespoke.loudspeakerModelB38'} />
        <TemplateItem name={'232'} translationName={'bespoke.amplifierModel232'} />
        <TemplateItem name={'159'} translationName={'bespoke.amplifierModel159'} />
        <TemplateItem name={'216'} translationName={'bespoke.amplifierModel216'} />
        <TemplateItem name={'218'} translationName={'bespoke.amplifierModel218'} />
        <TemplateItem name={'909'} translationName={'bespoke.amplifierModel909'} />
        <TemplateItem name={'111'} translationName={'bespoke.musicCenter111'} />
        <div className="text-center mb-32 text-gray-15">
          <p>{t('bespoke.pageDescriptionPart5')}</p>
          <p>{t('bespoke.pageDescriptionPart6')}</p>
        </div>
        <div
          className="flex"
          style={{
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div className="w-1/3">
            <img src={christianLangnerImg} className="object-cover" alt={'Christian Langner'} />
          </div>
          <div className="w-2/3 p-6 bg-white flex flex-col justify-between">
            <div className="flex flex-col gap-1">
              <p className="font-bold text-gray-10">{t('bespoke.contact.title')}</p>
              <p className="text-gray-10">{t('bespoke.contact.subtitle')}</p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-bold text-gray-10">{contactPerson}</p>
              <p className="text-gray-10">{t('bespoke.contact.personRole')}</p>
              <p className="text-gray-10">
                {t('bespoke.contact.phone')}: {phoneNumber}
              </p>
              <p className="text-gray-10">
                {t('bespoke.contact.email')}: {contactPersonEmail}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
