import { headers } from '../../utils/apiHeaders'
import { BACKEND_URL } from '../../config'
import handleResponse from '../../utils/handleApiResponse'

export const fetchFaqList = () => {
  const language = localStorage.getItem('i18nextLng') === 'en' ? 'en' : 'de'
  const URL_API_FAQ_REQUEST = `${BACKEND_URL}/api/easy-rma/faq/${language}`
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }
  return fetch(URL_API_FAQ_REQUEST, requestOptions)
    .then(handleResponse)
    .then((data) => data)
}
