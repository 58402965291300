import { Action } from 'redux'

import { FaqType } from './faqRequest.domain'

export const FAQ_REQUEST_FETCH_START = 'FAQ/FETCH_START'
export const FAQ_REQUEST_FETCH_IN_PROGRESS = 'FAQ/FETCH_IN_PROGRESS'
export const FAQ_REQUEST_FETCH_DONE = 'FAQ/FETCH_DONE'
export const FAQ_REQUEST_FETCH_ERROR = 'FAQ/FETCH_ERROR'

export interface IFAQFetchStartAction extends Action {
  type: typeof FAQ_REQUEST_FETCH_START
}
export const fetchFAQStartAction = (): IFAQFetchStartAction => {
  return {
    type: FAQ_REQUEST_FETCH_START,
  }
}

export interface IFAQFetchInProgressAction extends Action {
  type: typeof FAQ_REQUEST_FETCH_IN_PROGRESS
}
export const fetchUserActionInProgress = (): IFAQFetchInProgressAction => {
  return {
    type: FAQ_REQUEST_FETCH_IN_PROGRESS,
  }
}

export interface IFAQFetchDoneAction extends Action {
  type: typeof FAQ_REQUEST_FETCH_DONE
  faqData: FaqType[]
}
export const fetchUserActionDone = (faqData: FaqType[]): IFAQFetchDoneAction => {
  return {
    type: FAQ_REQUEST_FETCH_DONE,
    faqData,
  }
}

export interface IFAQFetchErrorAction extends Action {
  type: typeof FAQ_REQUEST_FETCH_ERROR
  error: Error
}
export const createFAQActionError = (error: Error): IFAQFetchErrorAction => {
  return {
    type: FAQ_REQUEST_FETCH_ERROR,
    error,
  }
}
