import { AdminUserData } from '../../user/user.domain'
import { Dictionary, IDictionary } from '../../utils/dictionary'

export type DictionaryItem = {
  value: string
  subvalue?: string
  description?: string
}

export enum RepairRequestStatus {
  DATA_SENT = 'DATA_SENT',
  REQUEST_TAKEN = 'REQUEST_TAKEN',
  PARCEL_RECEIVED = 'PARCEL_RECEIVED',
  COST_ESTIMATION = 'COST_ESTIMATION',
  REPAIR_FINISHED = 'REPAIR_FINISHED',
  RECEIPT = 'RECEIPT',
  PARCEL_SENT_BACK = 'PARCEL_SENT_BACK',
}

export const dictionaryRepairRequestStatus: IDictionary<DictionaryItem> = new Dictionary([
  [
    RepairRequestStatus.DATA_SENT.toString(),
    {
      value: 'dictionaryRepairRequestStatus.dataSent.display.value',
      subvalue: 'dictionaryRepairRequestStatus.dataSent.display.subvalue',
      description: 'dictionaryRepairRequestStatus.dataSent.display.description',
    },
  ],
  [
    RepairRequestStatus.REQUEST_TAKEN.toString(),
    {
      value: 'dictionaryRepairRequestStatus.requestTaken.display.value',
      subvalue: 'dictionaryRepairRequestStatus.requestTaken.display.subvalue',
      description: 'dictionaryRepairRequestStatus.requestTaken.display.description',
    },
  ],
  [
    RepairRequestStatus.PARCEL_RECEIVED.toString(),
    {
      value: 'dictionaryRepairRequestStatus.parcelReceived.value',
      subvalue: 'dictionaryRepairRequestStatus.parcelReceived.subvalue',
      description: 'dictionaryRepairRequestStatus.parcelReceived.description',
    },
  ],
  [
    RepairRequestStatus.COST_ESTIMATION.toString(),
    {
      value: 'dictionaryRepairRequestStatus.costEstimation.display.value',
      description: 'dictionaryRepairRequestStatus.costEstimation.display.description',
    },
  ],
  [
    RepairRequestStatus.REPAIR_FINISHED.toString(),
    {
      value: 'dictionaryRepairRequestStatus.repairFinished.value',
      subvalue: 'dictionaryRepairRequestStatus.repairFinished.subvalue',
      description: 'dictionaryRepairRequestStatus.repairFinished.description',
    },
  ],
  [
    RepairRequestStatus.RECEIPT.toString(),
    {
      value: 'dictionaryRepairRequestStatus.receipt.display.value',
      description: 'dictionaryRepairRequestStatus.receipt.display.description',
    },
  ],
  [
    RepairRequestStatus.PARCEL_SENT_BACK.toString(),
    {
      value: 'dictionaryRepairRequestStatus.parcelSentBack.value',
      description: 'dictionaryRepairRequestStatus.parcelSentBack.description',
    },
  ],
])

export enum ProductCategory {
  CD_PLAYER = 'CD_PLAYER',
  DVD_PLAYER = 'DVD_PLAYER',
  RECORD_PLAYER = 'RECORD_PLAYER',
  PRE_AMPLIFIER = 'PRE_AMPLIFIER',
  PREAMP_PHONO = 'PREAMP_PHONO',
  AMPLIFIER = 'AMPLIFIER',
  AMPLIFIER_TWO_CHANNEL = 'AMPLIFIER_TWO_CHANNEL',
  AMPLIFIER_THREE_CHANNEL = 'AMPLIFIER_THREE_CHANNEL',
  AMPLIFIER_FIVE_CHANNEL = 'AMPLIFIER_FIVE_CHANNEL',
  AMPLIFIER_MONO = 'AMPLIFIER_MONO',
  FULL_AMPLIFIER = 'FULL_AMPLIFIER',
  POWER_CONDITIONER = 'POWER_CONDITIONER',
  SPEAKER = 'SPEAKER',
  COMPACT_SPEAKER = 'COMPACT_SPEAKER',
  CENTER_SPEAKER = 'CENTER_SPEAKER',
  SUBWOOFER = 'SUBWOOFER',
  AMBIENCE_SPEAKER = 'AMBIENCE_SPEAKER',
  INWALL_SPEAKER = 'INWALL_SPEAKER',
  NETWORK_PRODUCT = 'NETWORK_PRODUCT',
  CROSSOVER = 'CROSSOVER',
  TUNER = 'TUNER',
  DAC = 'DAC',
  DA_CONVERTER = 'DA_CONVERTER',
  SURROUND_PROCESSOR = 'SURROUND_PROCESSOR',
  MUSICCENTER = 'MUSICCENTER',
  ALL_IN_ONE = 'ALL_IN_ONE',
  // XT = 'XT',
  // '909_SYSTEM' = '909_SYSTEM',
}

export const dictionaryProductCategory: IDictionary<string> = new Dictionary([
  /// ToDo string --> DictionaryItem
  [ProductCategory.CD_PLAYER.toString(), 'CD Spieler'],
  [ProductCategory.DVD_PLAYER.toString(), 'DVD-Player'],
  [ProductCategory.RECORD_PLAYER.toString(), 'Plattenspieler'],
  [ProductCategory.PREAMP_PHONO.toString(), 'Phono-Vorverstärker'],
  [ProductCategory.AMPLIFIER.toString(), 'Endverstärker'],
  [ProductCategory.AMPLIFIER_TWO_CHANNEL.toString(), '2-Kanal Endstufe'],
  [ProductCategory.AMPLIFIER_THREE_CHANNEL.toString(), '3-Kanal Endstufe'],
  [ProductCategory.AMPLIFIER_FIVE_CHANNEL.toString(), '5-/6-Kanal Endstufe'],
  [ProductCategory.AMPLIFIER_MONO.toString(), 'Endverstärker (Mono)'],
  [ProductCategory.FULL_AMPLIFIER.toString(), 'Vollverstärker'],
  [ProductCategory.PRE_AMPLIFIER.toString(), 'Vorverstärker'],
  [ProductCategory.POWER_CONDITIONER.toString(), 'Power Conditioner'],
  [ProductCategory.SPEAKER.toString(), 'Lautsprecher'],
  [ProductCategory.INWALL_SPEAKER.toString(), 'InWall Lautsprecher'],
  [ProductCategory.COMPACT_SPEAKER.toString(), 'Kompaktlautsprecher'],
  [ProductCategory.CENTER_SPEAKER.toString(), 'Center Speaker'],
  [ProductCategory.SUBWOOFER.toString(), 'Subwoofer'],
  [ProductCategory.AMBIENCE_SPEAKER.toString(), 'Ambience Lautsprecher'],
  [ProductCategory.NETWORK_PRODUCT.toString(), 'Network Player'],
  [ProductCategory.CROSSOVER.toString(), 'Aktive Frequenzweiche'],
  [ProductCategory.TUNER.toString(), 'Tuner'],
  [ProductCategory.DAC.toString(), 'DA-Converter'],
  [ProductCategory.DA_CONVERTER.toString(), 'D/A-Wandler'],
  [ProductCategory.SURROUND_PROCESSOR.toString(), 'Surround Prozessor'],
  [ProductCategory.MUSICCENTER.toString(), 'Musiccenter'],
  [ProductCategory.ALL_IN_ONE.toString(), 'All-in-One'],
  // [ProductCategory.XT.toString(), 'XT'],
  // [ProductCategory['909_SYSTEM'].toString(), '909 System'],
])

export const limitedDictionaryProductCategory: IDictionary<string> = new Dictionary([
  [ProductCategory.CD_PLAYER.toString(), 'CD Spieler'],
  [ProductCategory.DVD_PLAYER.toString(), 'DVD-Player'],
  [ProductCategory.RECORD_PLAYER.toString(), 'Plattenspieler'],
  [ProductCategory.AMPLIFIER.toString(), 'Endverstärker'],
  [ProductCategory.FULL_AMPLIFIER.toString(), 'Vollverstärker'],
  [ProductCategory.PRE_AMPLIFIER.toString(), 'Vorverstärker'],
  [ProductCategory.POWER_CONDITIONER.toString(), 'Power Conditioner'],
  [ProductCategory.SPEAKER.toString(), 'Lautsprecher'],
  [ProductCategory.NETWORK_PRODUCT.toString(), 'Network Player'],
  [ProductCategory.CROSSOVER.toString(), 'Aktive Frequenzweiche'],
  [ProductCategory.TUNER.toString(), 'Tuner'],
  [ProductCategory.DA_CONVERTER.toString(), 'D/A-Wandler'],
  [ProductCategory.SURROUND_PROCESSOR.toString(), 'Surround Prozessor'],
  [ProductCategory.MUSICCENTER.toString(), 'Musiccenter'],
  // [ProductCategory.XT.toString(), 'XT'],
  // [ProductCategory['909_SYSTEM'].toString(), '909 System'],
])

export const dictionaryProductList: IDictionary<string[]> = new Dictionary([
  [
    ProductCategory.CD_PLAYER.toString(),
    [
      '916 CD-Player (1991-1998)',
      '917 CD-Player (1991-1995)',
      '937 CD-Player (1993-1997)',
      '939 CD-Player (1993-1999)',
      '941 CD-Player/Tuner (1994-1997)',
      '963 CD-Player (1996-1999)',
      '969 CD-Laufwerk (1996-2005)',
      '979 CD-Laufwerk (1997-2004)',
      '992 CD-Player (1999-2005)',
      '001 CD-Player (2000-2009)',
      '006 CD-Player (2000-2005)',
      '052 CD-Player (2006-2011)',
      '061 CD-Player (2006-heute)',
      '069 CD-Player (2007-heute)',
      '089 CD-Player (2009-heute)',
      '102 CD Player (2011-heute)',
      'Concerto CD-Player (1992-1995)',
    ],
  ],
  [ProductCategory.DVD_PLAYER.toString(), ['033 DVD-Player (2003-2009)']],
  [
    ProductCategory.DA_CONVERTER.toString(),
    [
      '920 D/A-Wandler  (1992-1998)',
      '970 D/A-Wandler  (1997-2000)',
      '970 SRC D/A-Wandler  (2000-2005)',
      '978 Tuner  (1997-2003)',
      '980 D/A-Wandler  (1998-2002)',
      '980 SRC D/A Wandler  (2002-2004)',
      '113 D/A Converter  (2012-heute)',
    ],
  ],
  [
    ProductCategory.MUSICCENTER.toString(),
    ['111 Musiccenter (2012-heute)', '151 MK2 Musiccenter (2014-heute)', '161 All-in-one'],
  ],
  [
    ProductCategory.FULL_AMPLIFIER.toString(),
    [
      '940 Vollverstärker (1994-1997)',
      '959 Vollverstärker (1995-2000)',
      '991 Vollverstärker (1999-2006)',
      '032 Vollverstärker (2003-heute)',
      '051 Vollverstärker (2005-2011)',
      '082 Vollverstärker (2008-heute)',
      '101 Vollverstärker (2013-heute)',
    ],
  ],
  [
    ProductCategory.PRE_AMPLIFIER.toString(),
    [
      '036 Power Amplifier',
      '082 Integrated Amplifier',
      '099 Preamplifier EOL',
      '159 Power Amplifier',
      '909 MK5 Power Amplifier',
      '911 MK3 Power Amplifier',
      '956 MK2 Power Amplifier',
      '777 Vorverstärker (1977)',
      '785 Vorverstärker (1979-1988)',
      '808 MK 1 Vorverstärker (1980-1983)',
      '808 MK 2 Vorverstärker (1983-1985)',
      '808 MK 3 Vorverstärker (1985-1997)',
      '808 MK 4 Vorverstärker (wurde nie produziert)',
      '808 MK 5 Vorverstärker (1997-heute)',
      '838 Phono-Vorverstärker (1983-1991)',
      '846 Vorverstärker (1984-1990)',
      '870 MK 1 Vorverstärker (1987-1990)',
      '870 MK 2 Vorverstärker (1990-1992)',
      '877 MK 1 Vorverstärker (1987-1990)',
      '877 MK 2 Vorverstärker (1990-1996)',
      '877 MK 2 XLR Vorverstärker (1996-2000)',
      '897 Vorverstärker (1989-1995)',
      'Concerto Vorverstärker (1992-1995)',
      '935 MK 1 Vorverstärker (1993-1997)',
      '935 MK 2 Vorverstärker (1997-2004)',
      '011 Vorverstärker (2001-2010)',
      '035 Vorverstärker (2005-heute)',
      '077 Vorverstärker (2008-heute)',
      '088 Vorverstärker (2009-heute)',
      '099 DAC-Vorverstärker (2010-heute)',
      '100 Phono-Vorverstärker (2010-heute)',
    ],
  ],
  [ProductCategory.RECORD_PLAYER.toString(), ['175 Plattenspieler', '217 Plattenspieler']],
  [
    ProductCategory.AMPLIFIER.toString(),
    [
      '216',
      '218',
      '828 MK 1 Endverstärker (Mono) (1982-1986)',
      '828 MK 2 Endverstärker (Mono) (1986-1990)',
      '850 Endverstärker (Mono) (1985-1992)',
      '878 Endverstärker (1987-1995)',
      '909 Endverstärker (1990-1996)',
      '911 MK 1 Endverstärker (1991-1993)',
      '911 MK 2 Endverstärker (1993-2001)',
      '911 MK 3 Endverstärker (2001-heute)',
      'Concerto Endverstärker (1992-1995)',
      '933 MK 1 Endverstärker (1993-1996)',
      '933 MK 2 Endverstärker (1996-2004)',
      '956 MK 1 Endverstärker (1995-1997)',
      '956 MK2 Endverstärker (1997-heute)',
      '036 2-Kanal Endstufe (2004-heute)',
      '037 3-Kanal Endstufe (2004-heute)',
      '039 5-/6-Kanal Endstufe (2004-heute)',
    ],
  ],
  [
    ProductCategory.SPEAKER.toString(),
    [
      '949 MK 1 (1994-1999)',
      '949 MK 2 (1999-2003)',
      '961 MK 1 (1996-2003)',
      '961 MK 2 (2001-2006)',
      '961 MK 3 (2006-heute)',
      '975 Leo (1997-2000)',
      '995 MK 1 (1999-2002)',
      '995 MK 2 (2001-2006)',
      '995 MK 3 (2005-2012)',
      'B97 (1997-2001)',
      'B99 (1999-2004)',
      'B10 (2011-heute)',
      'B15',
      'B18 (2017-heute)',
      'B28',
      'B20 (2009-2012)',
      'B25 (2007-heute)',
      'B30 (2009-2012)',
      'B38',
      'B50 (2003-2012)',
      'B80 (2003-2011)',
      'B80 MK 2 (2011-heute)',
      'B100 (2005-heute)',
      'BC350 (2018-heute)',
      'BC150 (2021-heute)',
      'BA71 (2014-heute)',
      'BA31 (2015)',
      'C8 (2004-heute)',
      'S8 (2004-heute)',
      '022 (2002-2003)',
      'Phase 3',
      'InWall S&C (2015-heute)',
      'InWall Woofer (2015-heute)',
    ],
  ],
  [
    ProductCategory.TUNER.toString(),
    [
      '915 Tuner (1991-1997)',
      'Concerto Tuner (1992-1995)',
      '931 Tuner (1993-1996)',
      '931 RDS Tuner (1996-2007)',
      '993 Tuner (1999-2005)',
      '053 Tuner (2006-2011)',
    ],
  ],
  [
    ProductCategory.SURROUND_PROCESSOR.toString(),
    [
      '007 Surround Prozessor (2003-heute)',
      '057 Surround Prozessor (2006-heute)',
      '087 Surround Prozessor (2008-heute)',
    ],
  ],
  [ProductCategory.CROSSOVER.toString(), ['866 Aktive Frequenzweiche (1986-1989)']],
  [
    ProductCategory.POWER_CONDITIONER.toString(),
    ['948 Power Conditioner (1994-heute)', '038 Power Conditioner (2004-2011)'],
  ],
  [ProductCategory.NETWORK_PRODUCT.toString(), ['150 Netzwerkspieler (2015)']],
  // [ProductCategory.XT.toString(), ['077 XT', '069 XT']],
  // [ProductCategory['909_SYSTEM'].toString(), ['909 System (1990-1996)']],
])

export enum Accessories {
  Cable = 'Kabel',
  Puck = 'Puck',
  Medium = 'Tonträger',
  Other = 'Anderes',
}

export type RepairRequestStatusData = {
  id?: number
  type: RepairRequestStatus
  files?: string[]
  additionalInfo?: string
  additionalInfoLink?: string
}

export type RepairRequestStatusForm = {
  type: RepairRequestStatus
  file?: FileList
  additionalInfo?: string
  additionalInfoLink?: string
}

export type RepairRequestData = {
  requestId?: string
  serialNumber: string
  purchaseDate?: string
  productModel: string
  newPackaging: boolean
  productCategory: ProductCategory
  requestDescription: string
  paymentAgreement: boolean
  rightsAgreement: boolean
  fullName?: string
  email?: string
  accessories: string
  read?: boolean
  lastModificationUser?: AdminUserData
  versionSoftware: string
  // warrantyClaim: boolean
  currentStatus?: RepairRequestStatus
  statuses?: RepairRequestStatusData[]
  requestDatetime?: Date
  modificationDatetime?: Date
}

export type RepairRequestForm = {
  requestId?: string
  serialNumber: string
  purchaseDate?: Date
  productModel: string
  newPackaging: 0 | 1
  requestDescription: string
  paymentAgreement: boolean
  rightsAgreement: boolean
  productCategory: ProductCategory
  invoice?: FileList
  accessories: string[]
  accessoriesOtherDescription?: string
  versionSoftware: string
  // warrantyClaim: boolean
  currentStatus?: RepairRequestStatus
  statuses?: RepairRequestStatusData[]
  requestDatetime?: Date
  modificationDatetime?: Date
}
