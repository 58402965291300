import downloadjs from 'downloadjs'

import { BACKEND_URL } from '../../config'
import { headers } from '../../utils/apiHeaders'

export const getPriceListFile = (rmaCustomerId: string, priceListType: string) => {
  const URL_API_DOWNLOAD_FILE =
    BACKEND_URL + `/api/price-list/${rmaCustomerId}/download/${priceListType}`
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }
  fetch(URL_API_DOWNLOAD_FILE, requestOptions)
    .then((resp) => resp.blob())
    .then((blob) => {
      const fileName = `${priceListType}.pdf`
      downloadjs(blob, fileName, 'application/octet-stream')
    })
}
