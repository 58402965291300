import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RouteLink } from '../common'
import './RepairRequestPage.scss'
import {
  limitedDictionaryProductCategory,
  dictionaryProductList,
  Accessories,
  RepairRequestForm,
  RepairRequestData,
} from '../../requests/repair-request/repairRequest.domain'
import { UserData } from '../../user/user.domain'
import { fetchUserStartAction } from '../../user/user.actions'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import NewPageTitle from '../common/new-page-title/NewPageTitle'
import { Button } from '../common/button/Button'
import { ErrorBox } from '../common/errorBox/ErrorBox'
import { Spinner } from '../common/spinner/Spinner'
import { noContentTypeHeaders } from '../../utils/apiHeaders'
import handleResponse from '../../utils/handleApiResponse'
import { BACKEND_URL } from '../../config'

export const convertRepairRequestFormToData = (form: RepairRequestForm): RepairRequestData => {
  const { accessories, accessoriesOtherDescription } = form
  return {
    requestId: form.requestId,
    serialNumber: form.serialNumber,
    purchaseDate: form.purchaseDate ? form.purchaseDate.toISOString().split('T')[0] : '',
    productModel: form.productModel,
    productCategory: form.productCategory,
    newPackaging: form.newPackaging === 1,
    requestDescription: form.requestDescription,
    paymentAgreement: form.paymentAgreement,
    rightsAgreement: form.rightsAgreement,
    versionSoftware: form.versionSoftware,
    // warrantyClaim: form.warrantyClaim,
    accessories: `${accessories.join(',')}${
      accessoriesOtherDescription ? ':' + accessoriesOtherDescription : ''
    }`,
    // currentStatus: form.currentStatus,
    // statuses: form.statuses,
    // requestDatetime: form.requestDatetime,
    // modificationDatetime: form.modificationDatetime,
  }
}

export const RepairRequestPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userStore = useAppSelector((state) => state.user)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [sendingForm, setSendingForm] = useState(false)
  const [invoiceFiles, setInvoiceFiles] = useState<FileList | null>(null)
  const [createdNewRma, setCreatedNewRma] = useState<boolean>(false)
  // const [sentAttachmentPurchaseProof, setSentAttachmentPurchaseProof] = useState<boolean | null>(
  //   false
  // )
  // const [sentAttachmentFiles, setSentAttachmentFiles] = useState<boolean | null>(false)
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false)
  const { register, handleSubmit, watch, errors } = useForm()

  const watchAccessories = watch('accessories')
  const watchedProductCategory = watch('productCategory')
  const productSerialInfo = t('repairRequest.form.productSerial.info')

  useEffect(() => {
    if (userStore && userStore.userData) {
      setUserData(userStore.userData)
    } else {
      dispatch(fetchUserStartAction())
    }
  }, [userStore, dispatch])

  useEffect(() => {
    if (createdNewRma) {
      navigate(RouteLink.REQUEST_SERVICE_THANKS)
    }
  }, [createdNewRma, dispatch, navigate])

  const fieldInvoiceValidator = {
    maxFiles: (value: FileList) => !(value.length > 3),
    maxFileSize: (value: FileList) => !Array.from(value).some((file) => file.size > 10_000_000),
  }
  const onSubmit = (data: RepairRequestForm) => {
    setSendingForm(true)
    const URL_API_REPAIR_REQUEST = BACKEND_URL + '/api/repair-requests'
    const formData = new FormData()
    const formObj = convertRepairRequestFormToData(data)
    formData.append(
      'requestData',
      new Blob([JSON.stringify(formObj)], { type: 'application/json' })
    )

    if (invoiceFiles && invoiceFiles.length > 0) {
      for (let i = 0; i < invoiceFiles.length; i += 1) {
        formData.append('invoice', invoiceFiles[i], invoiceFiles[i].name)
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: noContentTypeHeaders(),
      body: formData,
    }

    fetch(URL_API_REPAIR_REQUEST, requestOptions)
      .then(handleResponse)
      .then(() => setCreatedNewRma(true))
      .then(() => navigate(RouteLink.REQUEST_SERVICE_THANKS))
      .catch(() => {
        setCreatedNewRma(false)
        setShowErrorMessage(true)
        // throw Error(err.message)
      })
      .finally(() => {
        setSendingForm(false)
      })
  }

  const handleInvoiceUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement
    const files: FileList | null = inputElement.files
    setInvoiceFiles(files)
  }

  return userData ? (
    <div className="pb-20 pt-8">
      <NewPageTitle
        topText={t('repairRequest.topText')}
        title={t('repairRequest.title')}
        backButton
      >
        {t('repairRequest.pageDescription')}
      </NewPageTitle>
      {Object.keys(errors).length > 0 ? (
        <ErrorBox
          title={t('repairRequest.form.error')}
          text={` ${t('repairRequest.form.validationMessage')}`}
        />
      ) : (
        <></>
      )}
      {sendingForm ? <Spinner text={t('menu.contactForm.sendingData')} /> : <></>}
      <form
        className="repair-form form grid grid-cols-6 gap-6"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="col-span-6 lg:col-start-2 lg:col-span-4">
          <div className="grid grid-cols-2 gap-6 p-4">
            <div className="form-group ">
              <label htmlFor="productCategory">{`${t(
                'repairRequest.form.productCategory.label'
              )} *`}</label>
              <select
                className="form-control mt-4"
                ref={register({ required: true })}
                name="productCategory"
                defaultValue={undefined}
                value={undefined}
              >
                <option value={''}>{t('repairRequest.form.productCategory.placeholder')}</option>
                {limitedDictionaryProductCategory.map((k, v) => (
                  <option value={k} key={'productCategory-option-' + k}>
                    {v}
                  </option>
                ))}
              </select>
              {errors.productCategory && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.productCategory.error')}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="productModel">{`${t(
                'repairRequest.form.productModel.label'
              )} *`}</label>
              <select
                className="form-control mt-4"
                ref={register({ required: true })}
                name="productModel"
                defaultValue={undefined}
                value={undefined}
                disabled={!watchedProductCategory}
              >
                <option value={''}>{t('repairRequest.form.productModel.placeholder')}</option>
                {watchedProductCategory &&
                  dictionaryProductList.item(watchedProductCategory).map((v) => (
                    <option value={v} key={'productProduct-option-' + v}>
                      {v}
                    </option>
                  ))}
              </select>
              {errors.productModel && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.productModel.error')}
                </div>
              )}
            </div>
          </div>
          <div className="form-group p-4">
            <label htmlFor="serialNumber">{`${t(
              'repairRequest.form.productSerial.label'
            )} *`}</label>
            <input
              className="form-control mt-4"
              ref={register({ required: true })}
              type="text"
              name="serialNumber"
            />
            <p className="mt-2" dangerouslySetInnerHTML={{ __html: productSerialInfo }}></p>
            {errors.serialNumber && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.productSerial.error')}
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-6 p-4">
            <div className="form-group">
              <label htmlFor="purchaseDate">{t('repairRequest.form.purchaseDate')}</label>
              <input
                className="form-control mt-4"
                ref={register({ valueAsDate: true })}
                type="date"
                name="purchaseDate"
              />
            </div>
            <div className="form-group">
              <label htmlFor="invoice">{t('repairRequest.form.invoice.label')}</label>
              <input
                className="form-control bg-white mt-4"
                ref={register({ validate: fieldInvoiceValidator })}
                type="file"
                name="invoice"
                multiple
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInvoiceUpload(e)}
              />
              <p className="mt-2">{t('repairRequest.form.invoice.info')}</p>
              <p className="mt-2">{t('repairRequest.form.invoice.text')}</p>
              {errors.invoice?.type === 'maxFiles' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.invoice.errors.maxFiles')}
                </div>
              )}
              {errors.invoice?.type === 'maxFileSize' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.invoice.errors.maxFileSize')}
                </div>
              )}
              {errors.invoice?.type === 'fileType' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.invoice.errors.fileType')}
                </div>
              )}
            </div>
          </div>
          {/*<div className="form-group p-4">*/}
          {/*  <div className="form-check form-check-inline form-check-custom mt-5">*/}
          {/*    <label className="form-check-label bold pt-0.5" htmlFor="warrantyClaim">*/}
          {/*      {t('repairRequest.form.warrantyTask.text')}*/}
          {/*      <input*/}
          {/*        className="form-check-input"*/}
          {/*        ref={register}*/}
          {/*        type="checkbox"*/}
          {/*        id="warrantyClaim"*/}
          {/*        name="warrantyClaim"*/}
          {/*        value={1}*/}
          {/*      />*/}
          {/*      <span className="checkmark" />*/}
          {/*    </label>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="form-group p-4">
            <div className="form-group">
              <label htmlFor="versionSoftware">
                {`${t('repairRequest.form.versionSoftware.label')}`}
              </label>
              <input
                ref={register}
                className="form-control mt-4"
                type="text"
                name="versionSoftware"
              />
            </div>
          </div>
          <div className="grid p-4">
            <div className="form-group">
              <div className="form-group">
                <label htmlFor="newPackaging">
                  {`${t('repairRequest.form.newPackaging.label')} *`}
                </label>
                <div className="mt-3">
                  <div className="form-check form-check-inline form-check-custom mr-5 mb-2">
                    <label className="form-check-label" htmlFor="newPackaging-true">
                      {t('repairRequest.form.newPackaging.options.yes')}
                      <input
                        className="form-check-input"
                        ref={register({ required: true })}
                        id="newPackaging-true"
                        type="radio"
                        value={1}
                        name="newPackaging"
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-check form-check-inline form-check-custom mr-5">
                    <label className="form-check-label" htmlFor="newPackaging-false">
                      {t('repairRequest.form.newPackaging.options.no')}
                      <input
                        className="form-check-input"
                        ref={register({ required: true })}
                        id="newPackaging-false"
                        type="radio"
                        value={0}
                        name="newPackaging"
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                {errors.newPackaging?.type === 'required' && (
                  <div className="text-red-500 font-bold mt-2">
                    {t('repairRequest.form.newPackaging.error')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group p-4">
            <label htmlFor="requestDescription">{`${t(
              'repairRequest.form.productDescription.label'
            )}:*`}</label>
            <textarea
              className="form-control form-textarea"
              rows={5}
              ref={register({ required: true, minLength: 20 })}
              name={'requestDescription'}
            />
            {errors.requestDescription?.type === 'required' && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.productDescription.errors.required')}
              </div>
            )}
            {errors.requestDescription?.type === 'minLength' && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.productDescription.errors.minLength')}
              </div>
            )}
          </div>
          <div className="form-group p-4">
            <label>{t('repairRequest.form.accessories.title')}</label>
            <div className="flex flex-col lg:flex-row">
              {Object.entries(Accessories).map(([value], index) => (
                <div
                  key={index}
                  className="form-check form-check-inline form-check-custom mr-5 py-3"
                >
                  <label className="form-check-label" htmlFor={`accessories-${index}`}>
                    {t(`repairRequest.form.accessories.${value}`)}
                    <input
                      className="form-check-input"
                      ref={register}
                      id={`accessories-${index}`}
                      type="checkbox"
                      value={index + 1}
                      name="accessories"
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              ))}
              {watchAccessories && watchAccessories.includes('4') && (
                <div className="col-auto flex-grow-1">
                  <input
                    className="form-control"
                    ref={register({ required: true })}
                    type="text"
                    name="accessoriesText"
                  />
                </div>
              )}
              {errors.accessoriesText && (
                <div className="text-red-500 font-bold ml-4 mt-3">
                  {t('repairRequest.form.accessories.error')}
                </div>
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className="form-group">
              <label>Kosten für die Analyse des Geräts</label>
              <p className="mt-2">
                Burmester führt im Rahmen der Reparatur- und Wartungsarbeiten eines Geräts
                umfassende Analysearbeiten durch, um eine vollständige Qualitätssicherung zu
                gewährleisten. Dafür berechnet Burmester eine einmalige Bearbeitungsgebühr von
                300,00 € zzg. Mwst. (Netto). Diese Kosten werden ebenfalls dann erhoben, sollten Sie
                das Gerät nicht durch Burmester reparieren oder warten lassen und wird Ihnen im
                Anschluss an die Analyse zugestellt. Im Falle einer Beauftragung werden die Kosten
                für die Analyse des Geräts mit den Gesamtkosten der Reparatur oder Wartung
                verrechnet. Die Kosten entfallen, wenn das Gerät innerhalb der gültigen Garantie-
                und Gewährleistungsfristen bei Burmester eingereicht wurde.
              </p>
              <p className="mt-2">
                Mit dem Anlegen einer RMA-Vorgangsnummer erkläre ich mich damit einverstanden, dass
                Burmester den Versand organisiert und mich darüber in Kenntnis setzt, so dass das
                einzusendende Produkt am Tag der Abholung fertig verpackt vorliegt. Die RMA Nummer
                ist durch mich gut leserlich auf dem Versandkarton oder Flightcase aufzubringen.
                <br />
                <br /> Im Falle einer Garantiereparatur übernimmt Burmester die Kosten für die
                Logistik in beide Richtungen.
                <br />
                <br /> Im Falle einer regulären Reparatursendung, die entweder nicht von der
                Garantie abgedeckt ist oder die eine Modifikation oder ein Upgrade ist, werden mir
                die Kosten für die RMA-Lieferung an Burmester mit der Servicerechnung vorgelegt. Die
                Regelung der Rücksendung an meine Adresse (sofern nicht anders angegeben) erfolgt
                nach dem vereinbarten Verfahren wie beim regulären Kauf von Waren.
              </p>
              <div className="form-check form-check-inline form-check-custom mt-5">
                <label className="form-check-label" htmlFor="paymentAgreement">
                  *Ich stimme zu, die Kosten in höhe von 300,00 € zzg. Mwst. (Netto), die durch die
                  Bearbeitung anfallen, zu übernehmen. Diese werden mir bei meinem Reparaturauftrag
                  erstattet. Ich bin damit einverstanden, dass Reparaturen bis zu einem
                  Reparaturwert (netto) von 500,00 € sofort und ohne weitere Rücksprache
                  durchgeführt werden.
                  <input
                    className="form-check-input"
                    ref={register({ required: true })}
                    type="checkbox"
                    id="paymentAgreement"
                    name="paymentAgreement"
                  />
                  <span className="checkmark" />
                </label>
              </div>
              {errors.paymentAgreement?.type === 'required' && (
                <div className="text-red-500 font-bold mt-2">{t('common.requiredFields')}</div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <div className="form-group">
              <div className="form-check form-check-inline form-check-custom">
                <label className="form-check-label" htmlFor="rightsAgreement">
                  *Wir behalten uns vor, für den Rückversand einen Originalkarton zu berechnen,
                  sollte der eingeschickte Karton keinen sachgemäßen Rückversand ermöglichen
                  <input
                    className="form-check-input"
                    ref={register({ required: true })}
                    type="checkbox"
                    id="rightsAgreement"
                    name="rightsAgreement"
                  />
                  <span className="checkmark" />
                </label>
              </div>
              {errors.rightsAgreement?.type === 'required' && (
                <div className="text-red-500 font-bold mt-2">{t('common.requiredFields')}</div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-[400px,1fr] gap-6 p-3">
            <div className="form-group">
              <div>{t('common.requiredFields')}</div>
            </div>
            <div className="form-group">
              <div className="text-right">
                <Button
                  disabled={sendingForm}
                  className={sendingForm ? 'opacity-50 cursor-not-allowed' : ''}
                  type="submit"
                >
                  {t('repairRequest.form.submitButton')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showErrorMessage ? (
        <ErrorBox
          title={t('repairRequest.error')}
          text={` ${t('repairRequest.errorMessage')}`}
          onClick={() => setShowErrorMessage(false)}
        />
      ) : (
        <></>
      )}
    </div>
  ) : null
}
