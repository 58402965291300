import { BACKEND_URL } from '../config'

export const login = (username: string, password: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }

  return fetch(`${BACKEND_URL}/api/login`, requestOptions)
    .then(handleResponse)
    .then((token) => {
      if (token) {
        return token
      } else {
        return null
      }
    })
}

function handleResponse(response: Response) {
  if (response.ok) {
    return Promise.resolve(response.headers.get('Authorization'))
  } else if (response.status === 403 || response.status === 401) {
    throw Error('Unauthorized')
  } else {
    return Promise.reject(response.statusText)
  }
}
