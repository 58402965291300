export const Option = ({
  children,
  expanded = false,
}: {
  children: React.ReactNode
  expanded?: boolean
}) => {
  return (
    <div className="bg-white z-10 shadow-custom pl-10 pr-6">
      <div className="flex flex-col">
        <div className="basic-1/3">
          <div className="flex">
            <div className="w-11/12 flex items-center">
              <span className="text-gray-15 font-untitled-sans text-sm tracking-wider">
                {children}
              </span>
            </div>
            <div className="w-1/12 text-end mt-4">
              <span className="text-6xl font-normal text-gray">{expanded ? '-' : '+'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
