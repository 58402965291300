import { Action } from 'redux'

import { AdminRepairRequestsFilter } from './adminRepairRequest.domain'

import {
  RepairRequestData,
  RepairRequestStatusForm,
} from '../../requests/repair-request/repairRequest.domain'

export const ADMIN_REPAIR_REQUEST_FETCH_LIST_START = 'ADMIN/REPAIR_REQUEST/FETCH_LIST_START'
export const ADMIN_REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS =
  'ADMIN/REPAIR_REQUEST/FETCH_LIST_IN_PROGRESS'
export const ADMIN_REPAIR_REQUEST_FETCH_LIST_DONE = 'ADMIN/REPAIR_REQUEST/FETCH_LIST_DONE'
export const ADMIN_REPAIR_REQUEST_FETCH_LIST_ERROR = 'ADMIN/REPAIR_REQUEST/FETCH_LIST_ERROR'

export const ADMIN_REPAIR_REQUEST_FETCH_DETAILS_START = 'ADMIN/REPAIR_REQUEST/FETCH_DETAILS_START'
export const ADMIN_REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS =
  'ADMIN/REPAIR_REQUEST/FETCH_DETAILS_IN_PROGRESS'
export const ADMIN_REPAIR_REQUEST_FETCH_DETAILS_DONE = 'ADMIN/REPAIR_REQUEST/FETCH_DETAILS_DONE'
export const ADMIN_REPAIR_REQUEST_FETCH_DETAILS_ERROR = 'ADMIN/REPAIR_REQUEST/FETCH_DETAILS_ERROR'

export const ADMIN_REPAIR_REQUEST_PUT_STATUS_START = 'ADMIN/REPAIR_REQUEST/PUT_STATUS_START'
export const ADMIN_REPAIR_REQUEST_PUT_STATUS_IN_PROGRESS =
  'ADMIN/REPAIR_REQUEST/PUT_STATUS_IN_PROGRESS'
export const ADMIN_REPAIR_REQUEST_PUT_STATUS_DONE = 'ADMIN/REPAIR_REQUEST/PUT_STATUS_DONE'
export const ADMIN_REPAIR_REQUEST_PUT_STATUS_ERROR = 'ADMIN/REPAIR_REQUEST/PUT_STATUS_ERROR'

export const ADMIN_REPAIR_REQUEST_DELETE_START = 'ADMIN/REPAIR_REQUEST/DELETE_START'
export const ADMIN_REPAIR_REQUEST_DELETE_IN_PROGRESS = 'ADMIN/REPAIR_REQUEST/DELETE_IN_PROGRESS'
export const ADMIN_REPAIR_REQUEST_DELETE_DONE = 'ADMIN/REPAIR_REQUEST/DELETE_DONE'
export const ADMIN_REPAIR_REQUEST_DELETE_ERROR = 'ADMIN/REPAIR_REQUEST/DELETE_ERROR'

// admin list
export interface IFetchAdminRepairRequestsListStartAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_LIST_START
  filter: AdminRepairRequestsFilter
}
export const fetchAdminRepairRequestsListStart = (
  filter: AdminRepairRequestsFilter
): IFetchAdminRepairRequestsListStartAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_FETCH_LIST_START,
    filter,
  }
}

export interface IFetchAdminRepairRequestsListInProgressAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS
}
export const fetchAdminRepairRequestsListInProgress =
  (): IFetchAdminRepairRequestsListInProgressAction => {
    return {
      type: ADMIN_REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS,
    }
  }

export interface IFetchAdminRepairRequestsListDoneAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_LIST_DONE
  requestDataList: Array<RepairRequestData>
}
export const fetchAdminRepairRequestsListDone = (
  requestDataList: Array<RepairRequestData>
): IFetchAdminRepairRequestsListDoneAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_FETCH_LIST_DONE,
    requestDataList,
  }
}

export interface IFetchAdminRepairRequestsListErrorAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_LIST_ERROR
  error: Error
}
export const fetchAdminRepairRequestsListError = (
  error: Error
): IFetchAdminRepairRequestsListErrorAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_FETCH_LIST_ERROR,
    error,
  }
}

// admin details
export interface IFetchAdminRepairRequestDetailsStartAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_DETAILS_START
  repairRequestId: string
}
export const fetchAdminRepairRequestDetailsStart = (
  repairRequestId: string
): IFetchAdminRepairRequestDetailsStartAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_FETCH_DETAILS_START,
    repairRequestId,
  }
}

export interface IFetchAdminRepairRequestDetailsInProgressAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS
}
export const fetchAdminRepairRequestDetailsInProgress =
  (): IFetchAdminRepairRequestDetailsInProgressAction => {
    return {
      type: ADMIN_REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS,
    }
  }

export interface IFetchAdminRepairRequestDetailsDoneAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_DETAILS_DONE
  requestData: RepairRequestData
}
export const fetchAdminRepairRequestDetailsDone = (
  requestData: RepairRequestData
): IFetchAdminRepairRequestDetailsDoneAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_FETCH_DETAILS_DONE,
    requestData,
  }
}

export interface IFetchAdminRepairRequestDetailsErrorAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_FETCH_DETAILS_ERROR
  error: Error
}
export const fetchAdminRepairRequestDetailsError = (
  error: Error
): IFetchAdminRepairRequestDetailsErrorAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_FETCH_DETAILS_ERROR,
    error,
  }
}
// admin details post
export interface IPutAdminRepairRequestStatusStartAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_PUT_STATUS_START
  repairRequestStatusForm: RepairRequestStatusForm
  requestId: string
}
export const putAdminRepairRequestStatusStart = (
  requestId: string,
  repairRequestStatusForm: RepairRequestStatusForm
): IPutAdminRepairRequestStatusStartAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_PUT_STATUS_START,
    repairRequestStatusForm,
    requestId,
  }
}

export interface IPutAdminRepairRequestStatusInProgressAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_PUT_STATUS_IN_PROGRESS
}
export const putAdminRepairRequestStatusInProgress =
  (): IPutAdminRepairRequestStatusInProgressAction => {
    return {
      type: ADMIN_REPAIR_REQUEST_PUT_STATUS_IN_PROGRESS,
    }
  }

export interface IPutAdminRepairRequestStatusDoneAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_PUT_STATUS_DONE
  requestData: RepairRequestData
}
export const putAdminRepairRequestStatusDone = (
  requestData: RepairRequestData
): IPutAdminRepairRequestStatusDoneAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_PUT_STATUS_DONE,
    requestData,
  }
}

export interface IPutAdminRepairRequestStatusErrorAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_PUT_STATUS_ERROR
  error: Error
}
export const putAdminRepairRequestStatusError = (
  error: Error
): IPutAdminRepairRequestStatusErrorAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_PUT_STATUS_ERROR,
    error,
  }
}

// admin delete repair request
export interface IDeleteAdminRepairRequestStartAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_DELETE_START
  repairRequestId: string
}
export const deleteAdminRepairRequestStart = (
  repairRequestId: string
): IDeleteAdminRepairRequestStartAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_DELETE_START,
    repairRequestId,
  }
}

export interface IDeleteAdminRepairRequestInProgressAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_DELETE_IN_PROGRESS
}
export const deleteAdminRepairRequestInProgress = (): IDeleteAdminRepairRequestInProgressAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_DELETE_IN_PROGRESS,
  }
}

export interface IDeleteAdminRepairRequestDoneAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_DELETE_DONE
  repairRequestId: string
}
export const deleteAdminRepairRequestDone = (
  repairRequestId: string
): IDeleteAdminRepairRequestDoneAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_DELETE_DONE,
    repairRequestId,
  }
}

export interface IDeleteAdminRepairRequestErrorAction extends Action {
  type: typeof ADMIN_REPAIR_REQUEST_DELETE_ERROR
  error: Error
}
export const deleteAdminRepairRequestError = (
  error: Error
): IDeleteAdminRepairRequestErrorAction => {
  return {
    type: ADMIN_REPAIR_REQUEST_DELETE_ERROR,
    error,
  }
}
