import { Reducer } from 'redux'

import {
  ADMIN_REPAIR_REQUEST_DELETE_ERROR,
  ADMIN_REPAIR_REQUEST_DELETE_IN_PROGRESS,
  ADMIN_REPAIR_REQUEST_DELETE_DONE,
  ADMIN_REPAIR_REQUEST_FETCH_DETAILS_DONE,
  ADMIN_REPAIR_REQUEST_FETCH_DETAILS_ERROR,
  ADMIN_REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS,
  ADMIN_REPAIR_REQUEST_FETCH_LIST_DONE,
  ADMIN_REPAIR_REQUEST_FETCH_LIST_ERROR,
  ADMIN_REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS,
  ADMIN_REPAIR_REQUEST_PUT_STATUS_DONE,
  ADMIN_REPAIR_REQUEST_PUT_STATUS_ERROR,
  ADMIN_REPAIR_REQUEST_PUT_STATUS_IN_PROGRESS,
  IDeleteAdminRepairRequestErrorAction,
  IDeleteAdminRepairRequestInProgressAction,
  IFetchAdminRepairRequestDetailsDoneAction,
  IFetchAdminRepairRequestDetailsErrorAction,
  IFetchAdminRepairRequestDetailsInProgressAction,
  IFetchAdminRepairRequestsListDoneAction,
  IFetchAdminRepairRequestsListErrorAction,
  IFetchAdminRepairRequestsListInProgressAction,
  IPutAdminRepairRequestStatusDoneAction,
  IPutAdminRepairRequestStatusErrorAction,
  IPutAdminRepairRequestStatusInProgressAction,
  IDeleteAdminRepairRequestDoneAction,
} from './adminRepairRequest.action'

import { RepairRequestData } from '../../requests/repair-request/repairRequest.domain'

export type AdminRepairRequestStateType = {
  list: RepairRequestData[]
  details?: RepairRequestData
  inProgress?: boolean
  error?: Error
}

type AdminRepairRequestActionType =
  | IFetchAdminRepairRequestsListInProgressAction
  | IFetchAdminRepairRequestsListDoneAction
  | IFetchAdminRepairRequestsListErrorAction
  | IFetchAdminRepairRequestDetailsInProgressAction
  | IFetchAdminRepairRequestDetailsDoneAction
  | IFetchAdminRepairRequestDetailsErrorAction
  | IPutAdminRepairRequestStatusInProgressAction
  | IPutAdminRepairRequestStatusDoneAction
  | IPutAdminRepairRequestStatusErrorAction
  | IDeleteAdminRepairRequestInProgressAction
  | IDeleteAdminRepairRequestDoneAction
  | IDeleteAdminRepairRequestErrorAction

const initialState: AdminRepairRequestStateType = {
  list: [],
}

const reducer: Reducer<AdminRepairRequestStateType, AdminRepairRequestActionType> = (
  state = initialState,
  action: AdminRepairRequestActionType
) => {
  switch (action.type) {
    case ADMIN_REPAIR_REQUEST_FETCH_LIST_IN_PROGRESS:
    case ADMIN_REPAIR_REQUEST_FETCH_DETAILS_IN_PROGRESS:
    case ADMIN_REPAIR_REQUEST_PUT_STATUS_IN_PROGRESS:
    case ADMIN_REPAIR_REQUEST_DELETE_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case ADMIN_REPAIR_REQUEST_FETCH_LIST_DONE:
      return {
        ...state,
        list: action.requestDataList,
      }

    case ADMIN_REPAIR_REQUEST_FETCH_DETAILS_DONE:
      return {
        ...state,
        details: action.requestData,
      }

    case ADMIN_REPAIR_REQUEST_PUT_STATUS_DONE:
      return {
        ...state,
        details: action.requestData,
        inProgress: false,
      }

    case ADMIN_REPAIR_REQUEST_DELETE_DONE:
      return {
        ...state,
        list: state.list.filter(
          (repairRequest) => repairRequest.requestId !== action.repairRequestId
        ),
        inProgress: false,
      }

    case ADMIN_REPAIR_REQUEST_FETCH_LIST_ERROR:
    case ADMIN_REPAIR_REQUEST_FETCH_DETAILS_ERROR:
    case ADMIN_REPAIR_REQUEST_PUT_STATUS_ERROR:
    case ADMIN_REPAIR_REQUEST_DELETE_ERROR:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      }

    default:
      return state
  }
}

export default reducer
