import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../common/button/Button'

type FilterComponentType = {
  filterText: string
  onFilter: (e: ChangeEvent<HTMLInputElement>) => void
  handleFilterText: () => void
}
export const FilterUserComponent = ({
  filterText,
  onFilter,
  handleFilterText,
}: FilterComponentType) => {
  const { t } = useTranslation()
  return (
    <div className="bg-white pt-4 pb-6 flex items-center">
      <input
        name="searchFilter"
        id="searchFilter"
        type="search"
        className="border border-gray p-2"
        placeholder={t('common.searchUser')}
        value={filterText}
        onChange={onFilter}
        onKeyDown={() => handleFilterText()}
      />
      <Button className="button button-primary ml-2 text-xs" onClick={() => handleFilterText()}>
        Search
      </Button>
    </div>
  )
}
